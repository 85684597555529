import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { theme } from '../../../../theme';

export const BoxFooter = styled(Box)({
  background: '#0e0f12',
  paddingTop: '70px',
});

export const FooterContent = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  padding: '0px 10px',
  gap: '20px',
  '& h6': {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '12px',
    lineHeight: '15px',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    color: theme.palette.primary.color,
    marginBottom: '10px',
  },
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    padding: '0px 0px',
    gap: '0px',
  },
   
});

export const FooterSocial = styled(Box)({
  '& img': {
    marginBottom: '32px',
  },
});

export const SocialLinks = styled(Box)({
  display: 'flex',
});

export const SocialIco = styled(Box)({
  maxWidth: '30px',
  maxHeight: '30px',
  marginRight: '12px',
  transition: 'all 0.25s',
});

export const FooterList = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  '& a': {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    color: theme.palette.primary.color,
    opacity: 0.7,
    marginBottom: '10px',
    textDecoration: 'none',
    '&:hover': {
      transition: 'all 0.2s',
      outline: 'none',
      boxShadow: 'none',
    },
  },
});

export const FooterApp = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  marginRight: '33px',
  '& img': {
    width: '135px',
    borderRadius: '6px',
    marginBottom: '19px',
  },
});

export const BoxCopyright = styled(Box)({
  borderTop: '1px solid hsla(0, 0%, 100%, 0.12)',
  marginTop: '55px',
  textAlign: 'center',
  padding: '32px',
  lineHeight: '16px',
  opacity: 0.7,
  color: theme.palette.primary.color,
  textTransform: 'uppercase',
  '& h6': {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    fontFamily: 'Montserrat',
  },
});
