import React, { useState, useEffect, useContext } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { removeRegister } from '../../../../app/features/appSlice';
import { loginSuccess } from '../../../../app/features/user/userSlice';
import { GridAction, CardInputWrapper, BtnSend, ButtonCheckout } from '../payment.style';
import QuestionnaireService from '../../../../core/services/questionnaire.service';
import { theme } from '../../../../theme';
import AppHelper from '../../../../core/helpers/AppHelper';
import { DialogComponent } from '../../../../core/layouts/widgets/header/header.styles';
import ascapLogo from '../../../../assets/images/ascap-logo.svg';
import { PaymentContext } from '../../summary/summary.page';

const inputStyle = {
  iconColor: '#c4f0ff',
  color: theme.palette.primary.main,
  fontWeight: '500',
  fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
  fontSize: '22px',
  fontSmoothing: 'antialiased',
  ':-webkit-autofill': {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  '::placeholder': {
    color: 'rgba(0, 0, 0, 0.87)',
  },
};

const boxModalStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '100px 72px',
  position: 'relative',
  zIndex: '2',

  '& img': {
    display: 'block',
    maxWidth: '100%',
    height: '48px',
    filter: 'invert(100%) sepia(100%) saturate(0%) hue-rotate(349deg) brightness(101%) contrast(103%)',
  },
};

const questionnaireService = new QuestionnaireService();

const CheckoutWidget = (props) => {
  const dispatch = new useDispatch();
  const { app } = useSelector((state) => state.app);
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const { isProcessing, setProcessingTo } = useContext(PaymentContext);
  const [checkoutError, setCheckoutError] = useState(null);
  const [isOpenDocusignModal, isSetOpenDocusingModal] = useState(false);
  const [submit, setSubmit] = useState(false);

  const { businessProviders: providers } = app.register || [];

  useEffect(() => {
    if (submit) {
      dispatch(removeRegister());
      return navigate('/account');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit]);

  const _subscribe = async (data) => {
    let userRegistered = JSON.parse(JSON.stringify(app.register));
    userRegistered.paymentMethod = data.payment_method;

    try {
      const dataRegistered = Object.preventExtensions(userRegistered);
      const r = await questionnaireService.subscribe(dataRegistered);
      if (r) {
        setProcessingTo(false);
        dispatch(loginSuccess(r.data.user));
        isSetOpenDocusingModal(true);
        if (!providers.includes('1')) {
          dispatch(removeRegister());
          return navigate('/account');
        }
        isSetOpenDocusingModal(true);
      }
    } catch (e) {
      props.reloadClienteSecret();
      setCheckoutError(AppHelper.getError(e));
    }
  };

  const handleSubmit = async (event) => {
    setProcessingTo(true);
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const billinDetailsName = 'Juan';
    const cardElement = elements.getElement(CardNumberElement);
    const result = await stripe.confirmCardSetup(props.clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: { name: billinDetailsName },
      },
    });

    if (result.error) {
      setCheckoutError(result.error.message);
      setProcessingTo(false);
      // props.reloadClienteSecret();
    } else {
      await _subscribe(result.setupIntent);
      // Use to localhost
      // console.log(result.setupIntent);
      setProcessingTo(false);
    }
  };

  const handleCardDetailsChange = (event) => {
    event.error ? setCheckoutError(event.error.message) : setCheckoutError();
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography>Card number</Typography>
            <CardInputWrapper>
              <CardNumberElement options={{ style: { base: inputStyle } }} onChange={handleCardDetailsChange} />
            </CardInputWrapper>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              flexDirection: 'column',
            }}
          >
            <Typography>Expiration date</Typography>
            <CardInputWrapper>
              <CardExpiryElement options={{ style: { base: inputStyle } }} onChange={handleCardDetailsChange} />
            </CardInputWrapper>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              flexDirection: 'column',
            }}
          >
            <Typography>CVC</Typography>
            <CardInputWrapper>
              <CardCvcElement options={{ style: { base: inputStyle } }} onChange={handleCardDetailsChange} />
            </CardInputWrapper>
          </Grid>
        </Grid>
        <GridAction>
          <BtnSend type='submit' disabled={isProcessing || !stripe}>
            {isProcessing ? 'Processing...' : 'Pay'}
          </BtnSend>
        </GridAction>
        <Grid>
          <Typography color='red'>{checkoutError}</Typography>
        </Grid>
      </form>

      <DialogComponent open={isOpenDocusignModal}>
        <Box sx={boxModalStyle}>
          <img src={ascapLogo} alt='' />

          <Typography
            marginTop={4}
            variant='h1'
            component='h1'
            textAlign='center'
          >{`You have a pending document to sign`}</Typography>
          <Typography
            marginTop={2}
          >{`ASCAP licensing requires some additional documentation to finish registering. You can complete this through the docusign link in your email.`}</Typography>
          <Typography marginTop={2}>{`Check Your Email`}</Typography>
        </Box>
        <ButtonCheckout
          sx={{
            width: '80%',
            alignSelf: 'center',
            marginBottom: '64px',
          }}
          onClick={() => {
            setSubmit(true);
            isSetOpenDocusingModal(false);
          }}
        >
          Ok
        </ButtonCheckout>
      </DialogComponent>
    </>
  );
};

export default CheckoutWidget;

CheckoutWidget.propTypes = {
  clientSecret: PropTypes.string,
  reloadClienteSecret: PropTypes.func,
};
