import AppService from "./AppService";

class AgreementService extends AppService  {

  constructor() {
    super();
    this.path = this.getPath('agreements');
  }

  download(id) {
    return this.http.get(`web${this.path}/download/${id}`, {responseType: 'blob'});
  }

  downloadCover(id) {
    return this.http.get(`web${this.path}/coverage/download/${id}`, {responseType: 'blob'});
  }

  downloadAl() {
    return this.http.get(`web${this.path}/download-all`, {responseType: 'blob'});
  }


}

export default AgreementService;
