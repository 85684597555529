import { useState } from 'react';
import { at } from 'lodash';
import { useField } from 'formik';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import {Visibility, VisibilityOff} from '@mui/icons-material'


const Input = styled(TextField)({
  marginBottom: '20px'
})

export default function InputField(props) {
  const { ...rest } = props;
  const [field, meta] = useField(props);
  const [showPassword, setShowPassword] = useState(false);

  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return error;
    }
  }

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Input
      error={meta.touched && meta.error && true}
      helperText={_renderHelperText()}
      {...field}
      {...rest}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton 
              onClick={handleShowPassword}
            >
              { !showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
