import React from 'react'
import PropTypes from 'prop-types';
import { Field, ErrorMessage } from 'formik';
import styles  from './style.css';
import { Box } from '@mui/material';

const error = {
  color: '#F5A623',
  margin: '10px 0',
  fontSize: '14px',
  fontFamily: 'Montserrat',
  fontWeight: 'bold',
  width: '100%',
  textAlign: 'center',
};

const CheckboxGroupWidget  = (props) => {
  const { name, options, ...rest } = props;
  return (
    <>
      <Box
        sx={{
          display: (props.display) ? props.display : 'flex',
          flexDirection: {
            xs: 'column',
            sm: 'row',
          },
          width: '100%',
        }}
      >
        <Field name={name}>
          {({ field }) => {
            return options.map(option => {
              return (
                <React.Fragment key={option.key}>
                  <article className={styles.article}>
                    <input
                      type='checkbox'
                      id={option.value}
                      {...field}
                      {...rest}
                      value={option.value}
                      checked={field.value.includes(option.value)}
                    />
                    <label htmlFor={option.value}>{option.key}</label>
                  </article>
                </React.Fragment>
              )
            })
          }}
        </Field>
      </Box>
      <div style={error}>
        <ErrorMessage name={name} />
      </div>
    </>
  )
}

export default CheckboxGroupWidget;

CheckboxGroupWidget.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
  options: PropTypes.array,
  display: PropTypes.string,
}