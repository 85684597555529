import AppService from './AppService';
import { serialize } from 'object-to-formdata';

class AccountService extends AppService {
  constructor() {
    super();
    this.path = this.getPath('account');
  }

  getAccount() {
    return this.http.get(`web${this.path}`);
  }

  getMembership() {
    return this.http.get(`web${this.path}/membership`);
  }

  getAgreements() {
    return this.http.get(`web${this.path}/agreements`);
  }

  cancelMembership() {
    const data = {};
    const formData = serialize(data);
    return this.http.patch(`web${this.path}/cancel-annual-renewal`, formData);
  }

  subscribe(data) {
    const formData = serialize(data);
    return this.http.post(`web${this.path}/subscribe`, formData);
  }

  availablesProv() {
    return this.http.get(`web${this.path}/availables-providers`);
  }

  validatePackageCode (data) {
    const formData = serialize(data);
    return this.http.post(`/web${this.path}/package-code`, formData);
  }

  calculeRates(data) {
    const formData = serialize(data);
    return this.http.post(`/web${this.path}/calculate-rates`, formData);
  }
}

export default AccountService;
