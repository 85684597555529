import { serialize } from 'object-to-formdata';
import AppService from './AppService';

class QuestionnaireService extends AppService {
  constructor() {
    super();
    this.path = this.getPath('questionnaire');
  }

  validateEmail(data) {
    const formData = serialize(data);
    return this.http.post(`/web${this.path}/verify-email`, formData);
  }

  sendVerificationCode(data) {
    const formData = serialize(data);
    return this.http.post(`/web${this.path}/send-verification-code`, formData);
  }

  validateCode(data) {
    const formData = serialize(data);
    return this.http.post(`/web${this.path}/check-verification-code`, formData);
  }

  create(data) {
    const formData = serialize(data);
    return this.http.post(`/web${this.path}`, formData);
  }

  createSetupIntent() {
    return this.http.get(`/web/questionnaire/create-setup-intent`);
  }

  subscribe(data) {
    const formData = serialize(data);
    return this.http.post(`/web/questionnaire/subscribe`, formData);
  }

  calculeRates(data) {
    const formData = serialize(data);
    return this.http.post(`/web/questionnaire/calculate-rates`, formData);
  }

  listBusinessTypes() {
    return this.http.get(`/web${this.path}/business-type`);
  }

  validatePackageCode (data) {
    const formData = serialize(data);
    return this.http.post(`/web${this.path}/package-code`, formData);
  }

}

export default QuestionnaireService;
