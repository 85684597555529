import { Box, Grid, Typography } from "@mui/material";
import { styled } from "@mui/system";
import logo from "../../../assets/images/logo-fitradio.png";
import { theme } from "../../../theme";

import { Logo } from "../questionnaire-wizard.styles";

const TitleSecundary = styled(Typography)({
  fontFamily: 'Montserrat',
  fontSize: '1.4rem',
  textTransform: 'uppercase',
  color: theme.palette.primary.main,
  fontWeight: 'bold',
  marginBottom: '15px',
});

const ContainerText = styled(Grid)({
  margin: '20px 0',
});

const CustomLink = styled(Typography)({
  fontFamily: 'Montserrat',
  fontSize: '1rem',
  color: theme.palette.primary.main,
  marginBottom: '15px',
  textDecoration: 'underline',
  cursor: 'pointer',
  "&:hover": {
    color: theme.palette.primary.main,
  }
}
);

const TextBody = styled(Typography)({
  fontFamily: 'Montserrat',
  fontSize: '1rem',
  lineHeight: '1.4rem',
  fontWeight: '400',
  color: '#000000',
});

const AgreementPage = () => { 

  return (
    <>
      <Logo>
        <img alt="FitRadio" src={logo} />
      </Logo>
      <TitleSecundary
        variant="h2"
        textAlign="center"
      >{`LICENSING TERMS & AGREEMENT`}</TitleSecundary>
      <ContainerText>
        <TextBody component={"span"} variant={"body2"}>
          {`Thank you. Before you place your order, please review our `} <CustomLink component='a' target='_blank' href='https://www.fitradio.com/tos'>{`terms and conditions`}</CustomLink>{` and our `} <CustomLink component='a' target='_blank' href='https://www.fitradio.com/privacy'>{`privacy policy`}</CustomLink> {`. By placing your order you are agreeing to be bound by our terms and conditions, including, but not limited to the following:`}
        </TextBody>
      </ContainerText>
      <Box component={"ol"}>
        <Typography component={"li"}>
          <ContainerText>
            <TextBody component={"span"} variant={"body2"}>
              {`Upon submission of your order, FITRADIO will obtain licenses from the selected Performing Rights Organizations (PROs). By placing an order, you are authorizing FITRADIO to obtain such licenses on your behalf and you agree to be bound by the terms of each license.`}
            </TextBody>
          </ContainerText>
        </Typography>
        <Typography component={"li"}>
          <ContainerText>
            <TextBody component={"span"} variant={"body2"}>
              {`You authorize FITRADIO to act as your agent with the PRO. FITRADIO does not sublicense the license to you, the license is issued directly to you from the PRO. `}
            </TextBody>
          </ContainerText>
        </Typography>
        <Typography component={"li"}>
          <ContainerText>
            <TextBody component={"span"} variant={"body2"}>
              {`Each PRO license is a one-year term. Once your license has been issued by the PRO, the license cannot be canceled or refunded. By placing your order, you are agreeing to pay for the full one-year term.`}
            </TextBody>
          </ContainerText>
        </Typography>
        <Typography component={"li"}>
          <ContainerText>
            <TextBody component={"span"} variant={"body2"}>
              {`For your convenience, FITRADIO allows you to pay for your license in installment payments, however, this shall not affect your liability to pay for the full one-year. In the event you fail to fully pay for your license and any applicable fees, FITRADIO reserves all rights, including but not limited to the right to send your account to a debt collection agency.`}
            </TextBody>
          </ContainerText>
        </Typography>
        <Typography component={"li"}>
          <ContainerText>
            <TextBody component={"span"} variant={"body2"}>
              {`FITRADIO charges a management fee and processing fee for our services, which include procurement of the license and FITRADIO’s reporting services. You may be able to obtain the license directly from the PRO at a lower cost and without FITRADIO’s fees.`}
            </TextBody>
          </ContainerText>
        </Typography>
        <Typography component={"li"}>
          <ContainerText>
            <TextBody component={"span"} variant={"body2"}>
              {`Each license shall automatically renew at the conclusion of the one-year term, unless you notify us at least 24 hours in advance that you wish to opt-out of renewal. `}
            </TextBody>
          </ContainerText>
        </Typography>
        <Typography component={"li"}>
          <ContainerText>
            <TextBody component={"span"} variant={"body2"}>
              {`The rates charged by the PROs are subject to increase each year based on the consumer price index (CPI). Unless you cancel prior to the renewal, your rate for the renewed license will be the increased rate. FITRADIO will do our best to notify you of the applicable price increase each year to enable you to opt out of renewal prior to the 24-hour deadline. However, ultimately it is your responsibility to be aware of the annual price increase and your renewal deadlines.`}
            </TextBody>
          </ContainerText>
        </Typography>
        <Typography component={"li"} sx={{fontWeight: 'bold'}}>
          <ContainerText>
            <TextBody component={"span"} variant={"body2"} sx={{fontWeight: 'bold'}}>
              {`FITRADIO does not guarantee any or all of the PROs will issue a license to you. The placement of an order does not guarantee that a license will be issued. Typically if a PRO refuses to issue a license it is because there is a past due balance owed by the applicant. In the event that your license is denied, or declined, your charges will be refunded and your pass through license with FITRADIO will be canceled. `}
            </TextBody>
          </ContainerText>
        </Typography>
        <Typography component={"li"} sx={{fontWeight: 'bold'}}>
          <ContainerText>
            <TextBody component={"span"} variant={"body2"} sx={{fontWeight: 'bold'}}>
              {`Any license obtained by FITRADIO shall be valid from the date of issuance and shall not cover or prevent liability from past unlicensed use of music. The PROs may still seek to recover past due balances or damages from unlicensed activities that occurred prior to the license issuance.`}
            </TextBody>
          </ContainerText>
        </Typography>
        <Typography component={"li"}>
          <ContainerText>
            <TextBody component={"span"} variant={"body2"}>
              {`FITRADIO obtains the selected licenses from the PRO based on the information that you provide to FITRADIO. By submitting such information you are representing and warranting to FITRADIO and the PROs that this information is accurate and truthful. If FITRADIO and/or the PRO determines the information provided is inaccurate, your rates may be adjusted to reflect the accurate information, you will be liable for any back-fees that are applicable and/or your license may be revoked.`}
            </TextBody>
          </ContainerText>
        </Typography>
        <Typography component={"li"}>
          <ContainerText>
            <TextBody component={"span"} variant={"body2"}>
              {`FITRADIO has done its best to calculate these rates based on the information that PROs provide us, from time to time there may be errors and in that event, we will contact you to resolve the issue. We reserve the right to make adjustments to your rate or license in the event your information requires correction.`}
            </TextBody>
          </ContainerText>
        </Typography>
      </Box>
    </>
  );
};

export default AgreementPage;
