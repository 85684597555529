import { Routes, Route } from 'react-router-dom';
import AgreementsPage from '../../pages/agreements/agreements.page';
import SettingsPage from '../../pages/settings/settings.page';
import MembershipPage from '../../pages/membership/membership.page';
import AccountDocuments from '../../pages/documents/documents.page.js';
import AccountLayout from '../layouts/account.layout';
import AddProductPage from '../../pages/products/addProduct.page';

export const AccountRoute = () => (
  <Routes>
    <Route element={<AccountLayout />}>
      <Route index path='/' element={<AgreementsPage />} />
      <Route path='/settings' element={<SettingsPage />} />
      <Route path='/membership' element={<MembershipPage />} />
      <Route path='/documents' element={<AccountDocuments />} />
      <Route path='/add-product' element={<AddProductPage />} />
    </Route>
  </Routes>
);
