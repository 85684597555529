import { serialize } from 'object-to-formdata';
import AppService from "./AppService";

class AuthService extends AppService  {

  constructor() {
    super();
    this.path = this.getPath('auth');
  }

  login(data) {
    const formData = serialize(data);
    return this.http.post(`/web/auth/login`, formData);
  }

  me() {
    return this.http.get(`web${this.path}/me`);
  }

  resetPassword(data, token) {
    const formData = serialize(data);
    return this.http.post(`/web/auth/reset-password?token=${token}`, formData);
  }

  recoverPassword(data) {
    const formData = serialize(data);
    return this.http.post(`/web/auth/recover-password`, formData);
  }

  changePassword(data) {
    const formData = serialize(data);
    return this.http.post(`/web/auth/change-password`, formData);
  }

}

export default AuthService;
