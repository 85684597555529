import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { ContainerConfirmation } from "./confirmation.style";

const ConfirmationWidget = (props) => {

  useEffect(() => {
    const timer = setTimeout(() => {
      props.setShow(false);
    }, props.time);
    return () => clearTimeout(timer);
  }
    , [props]);

  return(
      <ContainerConfirmation>
       {
          props.show && <div>{props.message}</div>
       } 
      </ContainerConfirmation>
  )
};

export default ConfirmationWidget;

ConfirmationWidget.propTypes = {
  message: PropTypes.string,
  time: PropTypes.number || 1000,
  show: PropTypes.bool,
  setShow: PropTypes.func
}