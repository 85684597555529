import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Box, CircularProgress } from '@mui/material';
import QuestionnaireService from '../../../core/services/questionnaire.service';
import { ContainerSummary, TopSummary } from './payment.style';
import CheckoutWidget from './widgets/checkout.widget';
import AppHelper from '../../../core/helpers/AppHelper';
import UpdatePaymentMethodWidget from '../../membership/updatePaymentMethod.widget';

const questionnaireService = new QuestionnaireService();
const stripePromise = loadStripe(
  // eslint-disable-next-line no-undef
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
);

const PaymentyPage = ({ isEditMode, openDialog, setLoader }) => {
  const [options, setOptions] = useState({
    clientSecret: '',
  });

  const _getStripeIntent = useCallback(async () => {
    try {
      const r = await questionnaireService.createSetupIntent();
      if (r) {
        setOptions((options) => ({
          ...options,
          clientSecret: r.data.clientSecret,
        }));
      }
    } catch (e) {
      AppHelper.getError(e);
    }
  }, []);

  const _reloadStripeIntent = async () => {
    await _getStripeIntent();
  };

  const _init = useCallback(async () => {
    await _getStripeIntent();
  }, [_getStripeIntent]);

  useEffect(() => {
    (async function init() {
      await _init();
    })();
  }, [_init]);

  return (
    <>
      <ContainerSummary>
        <TopSummary>PAYMENT METHOD</TopSummary>
        {options.clientSecret !== '' ? (
          <Elements stripe={stripePromise} options={options}>
            {
              isEditMode 
                ? <UpdatePaymentMethodWidget 
                    clientSecret={options.clientSecret}
                    reloadClienteSecret={_reloadStripeIntent}
                    openDialog={openDialog}
                    setReload={setLoader}
                  />
                : <CheckoutWidget
                    clientSecret={options.clientSecret}
                    reloadClienteSecret={_reloadStripeIntent}
                  />
            }
          </Elements>
        ) : (
          <Box display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Box>
        )}
      </ContainerSummary>
    </>
  );
};

export default PaymentyPage;

PaymentyPage.defaultProps = {
  isEditMode: false,
}

PaymentyPage.propTypes = {
  isEditMode: PropTypes.bool,
  openDialog: PropTypes.func.isRequired,
  setLoader: PropTypes.func.isRequired,
}
