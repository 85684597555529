import { styled } from '@mui/material/styles';
import { Box, Button, Typography, Table } from '@mui/material';

import backgroundMembership from '../../../assets/images/background-membership.svg';

import { theme } from '../../../theme';

export const BoxMembership = styled(Box)({
    backgroundColor: theme.palette.primary.color,
    border: '1px solid #e8ecef',
    boxShadow: '0 16px 24px rgb(0 0 0 / 4%)',
    borderRadius: '4px',
    padding: '20px 1.5em',
    position: 'relative',
    marginBottom: '20px',
});

export const BoxField = styled(Box)({
    marginTop: '2em',
    '& .MuiOutlinedInput-input':{
        fontFamily: 'Montserrat',
        fontSize: '16px',
    },
});

export const TitleMembership = styled(Typography)({
    fontFamily: 'Bebas Neue',
    fontSize: '32px',
    fontWeight: 'bold',
    marginBottom: '0.8em'
});

export const LabelTitle = styled(Typography)({
    fontFamily: 'Montserrat',
    fontSize: '12px',
    fontWeight: 'bold',
    color: '#5E6469'
});

export const LabelText = styled(Typography)({
    fontFamily: 'Bebas Neue',
    fontSize: '28px',
    fontWeight: 'bold',
    marginBottom: '0.5em',
    color: '#010101'
});

export const BtnTurnOff = styled(Button)({
    display: 'flex',
    margin: '0 0 0 auto',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.color,
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    borderRadius: '2rem',
    fontSize: '14px',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    height: '56px',
    padding: '10px 20px',
    '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.color,
    },
    [theme.breakpoints.up('sm')]: {
        padding: '10px 40px',
    }
  });

  export const TableTitle = styled(Typography)({
    fontSize: '18px',
    fontWeight: 'bold',
    margin: '2.5em 1em 1em 0.5em',
    color: '#010101'
});

export const TableInformation = styled(Table)({
    '& .MuiTableRow-head': {
        '& .MuiTableCell-root': {
            textTransform: 'uppercase',
            fontWeight: 'bold',
            fontSize: '12px'
        }
    },
    '& .MuiTableCell-root': {
        fontSize: '14px'
    }
});

export const BoxDataMemberShip = styled(Box)({
    padding: '0 1em 2.5em 1em',
    background: `url(${backgroundMembership}) no-repeat center center`,
    backgroundSize: 'cover',
    marginTop: '2em',
    borderRadius: '1em'
});






