import backgroundWrapper from "../../assets/images/hero-bg.png";
import backgroundUpgrade from "../../assets/images/hero-bg.png";
import { theme } from "../../theme";

export const HomeStyles = () => ({

  navbarWrapper: {
    backgroundImage: `url(${backgroundWrapper})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    position: "relative",
  },

  heroInner: {
    padding: "206px 0 141px",
    maxWidth: '555px',
    color: theme.palette.primary.color,
    position: "relative",
    zIndex: "2",
  },

  title: {
    fontFamily: 'Bebas Neue',
    fontSize: '42px',
    fontWeight: 'bold',
    letterSpacing: '0.02em',
    textTransform: 'uppercase',
    color: '#eae9e9',
    lineHeight: '0.9',
    marginBottom: '12px',
    '& span':{
      color: theme.palette.primary.main
    },
    ['@media (min-width: 768px)'] : {
      fontSize: '58px',
    },
    ['@media (min-width: 992px)'] : {
      fontSize: '72px',
    }
  },

  subTitle: {
    fontFamily: 'Montserrat',
    fontSize: '16px',
    letterSpacing: '0.025em',
    lineHeight: '1.35',
    marginBottom: '35px'
  },

  btn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
    color: theme.palette.primary.color,
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    border: "1px solid transparent",
    borderRadius: "2rem",
    width: '100%',
    maxWidth: '220px',
    height: '100%',
    maxHeight: '64px',
    fontSize: "14px",
    letterSpacing: '1px',
    textTransform: 'uppercase',
    textDecoration: 'none',
    padding: "10px 17px",
    ['@media (min-width: 768px)'] : {
      padding: '20px 5px',
    },
    '&:hover' : {
      border: '2px solid hsla(0, 0%, 100%, 0.5)',
      opacity: '0.7',
      transition: 'all 0.25s',
      textDecoration: 'none'
    }
 },

  btnWhite: {
    backgroundColor: theme.palette.primary.color,
    color: "#010101",
    border: "2px solid hsla(0, 0%, 100%, 0)"
  },

  heroImg: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    ['@media (min-width: 575px)'] : {
      right: '4vw',
    },
  },

  adminHeroImg: {
    height: '80%',
    opacity: 0.2
  },

  logowall: {
    display: 'flex',
    alignItems: 'center',
    padding: '48px 0',
    borderTop: '1px solid hsla(0, 0%, 100%, 0.15)',
    position: 'relative',
    zIndex: '2',
    '& a': {
      paddingRight: '10vw',
      opacity: '0.5',
      '&:hover':{
        opacity: '1',
        transition: '0.5s'
      }

    },
    '& img':{
      display: 'block',
      maxWidth: '100%',
      height: 'auto'
    }
  },

  licensingBenefits: {
    backgroundColor: "#010101", 
    color: theme.palette.primary.color, 
    padding: "50px 0",
    ['@media (min-width:575px)'] : {
      padding: "120px 0 60px",
    }
  },

  titleWrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    marginBottom: '58px',
    ['@media (min-width:575px)'] : {
      flexDirection: "row",
      alignItems: "flex-end",
      justifyContent: "space-between",
      textAlign: "left",
    }
  },

  badgePrimary: {
    color: theme.palette.primary.main,
    background: "rgba(245, 166, 35, 0.15)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "4px 8px",
    borderRadius: "30px",
    fontFamily: 'Montserrat',
    fontSize: "12px",
    fontWeight: "600",
    lineHeight: "1",
    textTransform: "inherit",
    maxWidth: "max-content",
    letterSpacing: "0.1px",
  },

  bpHome: {
    margin: '0 0 20px'
  },

  bpHomeAdmin: {
    margin: 'auto',
    marginBottom: '20px'
  },

  sectionTitle: {
    margin: '0',
    color: theme.palette.primary.color,
    fontSize: "32px",
    fontFamily: 'Bebas Neue',
    fontWeight: "bold",
    lineHeight: "1",
    letterSpacing: "0.1vw",
    ['@media (min-width:768px)'] : {
      fontSize: "48px"
    }
  },

  sectionSubTitle: {
    margin: '15px',
    transform: 'scaleX(1.10)',
    fontFamily: 'Montserrat',
    fontSize: '15px',
    lineHeight: "1",
    ['@media (min-width:768px)'] : {
      fontSize: "16px"
    }
  },

  musicIcon: {
    width: '26px',
    height: '25px',
    marginBottom: '18px'
  },

  mgmIcon: {
    width: '32px',
    height: '32px',
    marginBottom: '18px'
  },
  
  paymentIcon: {
    width: '30px',
    height: '30px',
    marginBottom: '18px'
  },

  usaBasedIcon: {
    width: '51px',
    height: '30px',
    marginBottom: '18px'
  },

  licensingTitle: {  
    fontFamily: 'Bebas Neue',
    fontSize: '24px',
    lineHeight: '1',
    marginBottom: '16px',
  },

  txt: {
    fontFamily: 'Montserrat',
    opacity: '0.75',
    lineHeight: '1.35',
    fontSize: '14px'
  },

  licensingList: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    margin: '0 0 15px',
    fontSize: '14px',
    ['@media (min-width: 575px)'] : {
      margin:' 0 0 78px'
    },
  },

  elementList: {
    width: '100%',
    maxWidth: '100%',
    textAlign: 'center',
    '& p':{
      fontFamily: 'Montserrat',
      opacity: '0.75',
      lineHeight: '1.35',
      fontSize: '14px',
      marginBottom: '1rem',

    },
    ['@media (min-width: 575px)'] : {
      maxWidth: '50%',
      textAlign: 'left'
    },
    ['@media (min-width: 1200px)'] : {
      maxWidth: '240px'
    },

    ['@media (max-width: 991px)'] : {
      '& p':{
        maxWidth: '240px'
      }
    },

    ['@media (max-width: 574px)'] : {
      '& p':{
        margin: '0 auto 35px'
      }
    },

  },

  testimonalElement: {    
    width: '100%',
    maxWidth: '100%',
    textAlign: 'center',
    '& p': {       
      fontFamily: 'Montserrat',
      lineHeight: '1.35',
      fontSize: '20px',
    },
    ['@media (min-width: 575px)'] : {
      maxWidth: '50%',
      textAlign: 'left'
    },
    ['@media (min-width: 1200px)'] : {
      maxWidth: '300px'
    },

    ['@media (max-width: 991px)'] : {
      '& p':{
        maxWidth: '240px'
      }
    },

    ['@media (max-width: 574px)'] : {
      '& p':{
        margin: '0 auto 35px'
      }
    },
  },

  licensingRecommended: {
    fontFamily: 'Montserrat',
    fontStyle: 'italic',
    fontSize: '12px',
    lineHeight: 1,
    opacity: 0.5,
    borderTop: '1px solid hsla(0, 0%, 100%, 0.15)',
    padding: '14px 0 0',
    letterSpacing: '0.15px',
    '& p': {
      marginBottom: '7px',
    },
    '& a': {
      color: 'inherit',
      transition: 'all 0.2s',
      textDecoration: 'none',
      outline: 'none',
      boxShadow: 'none'
    }
  },

  upgradeSection: {
    backgroundImage: `url(${backgroundUpgrade})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    padding: '102px 0 75px',
    textAlign: 'center',
    color: theme.palette.primary.color,
  },

  titleUpgrade: {
    fontFamily: 'Bebas Neue',
    fontWeight: 'bold',
    letterSpacing: '0.02em',
    marginBottom: '3px',
    textTransform: 'uppercase',
    fontSize: '32px',
    ['@media (min-width: 768px)'] : {
      fontSize: '64px'
    },
    '& span': {
      color: theme.palette.primary.main
    }
  },

  wrapBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& a' : {
      margin: '15px 0 0',
      padding: '20px 25px',
      maxWidth: 'max-content'
    },
    ['@media (min-width: 575px)'] : {
      flexDirection: 'row',
      '& a' : {
        margin: '0 0 0 25px'
      },
    },
  },

  btnPrimary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.color,
    border: '2px solid hsla(0, 0%, 100%, 0)'
  },

  titleAdminWrap: {
    display: 'block',
    alignItems: 'center',
    textAlign: 'center',
    marginBottom: '58px',
  },

  boxImgTestimonals: {
    height: '90px',
    '& img': {
      position:'relative', 
      top: '50%', 
      transform: 'translateY(-50%)'
    }
  },

  quoteAuthorBox: {
    display: 'flex',
    marginTop: '30px'
  },

  boxTextAuthor: {
    marginLeft: '20px',
    '& h5': {
      fontFamily:'Montserrat',
      fontSize: '16px',
      color: theme.palette.primary.main
    },
    '& h6': {
      fontFamily:'Montserrat',
      fontSize: '14px'
    }
  }

});
