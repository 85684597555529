import {
  Container,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

import { PageTitle, PageSubtitle, BoxTable } from "./document.page.styles.js";

const AdminDocuments = () => {
  function createData( id, customerName, signatureStatus) {
    return { id, customerName, signatureStatus };
  }

  const rows = [
    createData(1, 'amogus', 'Pending Signature'),
    createData(2, 'amogus', 'Signature'),
    createData(3, 'amogus', 'Signature'),
    createData(4, 'amogus', 'Signature'),
    createData(5, 'amogus', 'Pending Signature'),
    createData(6, 'amogus', 'Pending Signature'),
    createData(7, 'amogus', 'Pending Signature'),
  ];

  return (
    <>
      <Container>
        <PageTitle>My documents: User</PageTitle>
        <PageSubtitle>View of my documents</PageSubtitle>
        <BoxTable>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Customer Name</TableCell>
                <TableCell align="left">Signature Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.customerName}
                  </TableCell>
                  <TableCell align="left">{row.signatureStatus}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </BoxTable>
      </Container>
    </>
  );
};

export default AdminDocuments;
