import { styled } from '@mui/material/styles';
import { Box, Button, Typography } from '@mui/material';
import { theme } from '../../../theme'

export const BoxAgreements = styled(Box)({
    backgroundColor: theme.palette.primary.color,
    border: '1px solid #e8ecef',
    boxShadow: '0 16px 24px rgb(0 0 0 / 4%)',
    borderRadius: '4px',
    padding: '20px 15px',
    position: 'relative',
    marginBottom: '20px',
});

export const TitleAgreements = styled(Typography)({
    fontFamily: 'Bebas Neue',
    fontSize: '32px',
    fontWeight: 'bold',
    marginBottom: '0.75em'
});

export const BoxDocument = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    background: '#EAE9E9',
    borderRadius: '1em',
    padding: '1.25em 1.5em',
    marginBottom: '1.25em',
    '& .MuiBox-root': {
        display: 'flex',
        '& .MuiTypography-root': {
            textTransform: 'uppercase',
            fontFamily: 'Montserrat',
            fontSize: '18px',
            fontWeight: 'bold',
            marginLeft: '1em'
        },
    },

    '& .MuiBox-root:nth-of-type(1)': {
        '& .MuiSvgIcon-root':{
            color: theme.palette.primary.main
        }
    },

    '& .MuiBox-root:nth-of-type(2)': {
        alignItems: 'center',
        '& .MuiTypography-root': {
            fontSize: '12px',
            letterSpacing: '1px',
            marginLeft: '0.5em'
        }
    }
});

export const BtnDownload = styled(Button)({
    display: 'flex',
    margin: '0 0 0 auto',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#000',
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    borderRadius: '2rem',
    fontSize: '14px',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    padding: '10px 40px',
    textDecoration: 'none',
    '& .MuiBox-root': {
        marginLeft: '0.5em'
    }
});

export const BtnAllDownload = styled(Button)({
    display: 'flex',
    margin: '0 0 0 auto',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.color,
    color: '#000',
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    border: '2px solid #000',
    borderRadius: '2rem',
    fontSize: '14px',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    padding: '10px 40px',
    textDecoration: 'none',
    '& .MuiBox-root': {
        marginLeft: '0.5em'
    }
});
