import PropTypes from "prop-types";
import { useState } from "react";
import { Grid, Typography, Button } from "@mui/material";
import { HeaderText, ContainerForm, SecundaryText } from "./form.styles";
import ErrorWidget from "../../../core/widgets/error/error.widget";
import ConfirmationWidget from "../../../core/widgets/confirmation/confirmation.widget";
import AppHelper from "../../../core/helpers/AppHelper";
import QuestionnaireService from "../../../core/services/questionnaire.service";
import InputFieldFormat from "../../../core/widgets/input.format.widget";

const VerificationCodeForm = (props) => {
  const {
    formField: { verificationCode },
  } = props;

  const questionnaireService = new QuestionnaireService();
  // eslint-disable-next-line no-unused-vars
  const [errorMsg, setErrorMsg] = useState(null);
  const [showConfirmationMsg, setShowConfirmationMsg] = useState(false);

  const _sendEmailAgain = async (data) => {
    try {
      await questionnaireService.sendVerificationCode(data);
      setShowConfirmationMsg(true);
    } catch (error) {
      setErrorMsg(AppHelper.getError(error));
    }
  };
  return (
    <>
      <HeaderText variant="body2" textAlign="center">
        Question 02/06
      </HeaderText>
      <Typography variant="h1" textAlign="center">
        Verify your Email
      </Typography>
      <SecundaryText>{`We sent a verification email to ${props.email}, check your email and input the code below.`}</SecundaryText>
      <ContainerForm container spacing={1}>
        <Grid item xs={12} md={12}>
          <InputFieldFormat
            format="###-###"
            name={verificationCode.name}
            label={verificationCode.label}
            fullWidth
          />
          <Button
            disabled={showConfirmationMsg}
            onClick={() => _sendEmailAgain(props)}
          >
            Send Again
          </Button>
          <Typography variant="h1" textAlign="center">
            {errorMsg}
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}></Grid>
        <ErrorWidget message={props.response} />
        <ConfirmationWidget
          message="New code sent!"
          time={2000}
          show={showConfirmationMsg}
          setShow={setShowConfirmationMsg}
        />
      </ContainerForm>
    </>
  );
};

export default VerificationCodeForm;

VerificationCodeForm.propTypes = {
  formField: PropTypes.object,
  response: PropTypes.string,
  email: PropTypes.string,
};
