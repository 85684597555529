import React from 'react';
import HeaderWidget from './widgets/header/header.widget';
import { Outlet } from 'react-router-dom';
import FooterWidget from './widgets/footer/footer.widget';

const WebLayout = () => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    }}>
      <HeaderWidget />
        <Outlet/>
      <FooterWidget />
    </div>
  );
};

export default WebLayout;
