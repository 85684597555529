import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  DialogTitle,
  DialogActions,
  DialogContent,
  Box,
  Typography,
  TextField,
} from '@mui/material';

import {
  DialogComponent,
  TitleSection,
  BoxForm,
  BtnLogin,
  BtnClose,
  BoxError
} from '../../core/layouts/widgets/header/header.styles';

import { ErrorMessage, Form, Formik } from 'formik';
import AuthService from '../../core/services/auth.service.js';
import AppHelper from '../../core/helpers/AppHelper';

import * as Yup from 'yup';

const SendEmailDialog = (props) => {
  const authService = new AuthService();
  const [textError, setTextError] = useState(null);
  const [showMessage, setShowMessage] = useState(false);

  const handleDialogClose = () => {
    props.setOpenDialog(false);
    setShowMessage(false);
    setTextError(null);
  };

  const initialValues = {
    email: ''
  };

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required('Email is required')
      .email('Please enter a valid e-mail')
  });

  const onSubmit = async (values) => {
    const data = {
      email: values.email
    };

    try {
      await authService.recoverPassword(data);
      setTextError('We have sent the instructions to your email to reset your password, please check your email or in the span folder ')
      setShowMessage(true);
    } catch (error) {
      setTextError(AppHelper.getError(error));
    }
  };

  return (
    <div>
      <DialogComponent open={props.openDialog} onClose={handleDialogClose}>
        <DialogTitle>
          <TitleSection>
            <Typography variant='h3'>Reset Password</Typography>
          </TitleSection>
        </DialogTitle>
        <DialogContent>
          <BoxForm>
            <Formik
              initialValues={initialValues}
              validationSchema={LoginSchema}
              onSubmit={onSubmit}
            >
              {(props) => {
                const { values, handleChange } = props;
                return (
                  <Form component='form'>
                    {
                      !showMessage && 
                      <>
                        <Box>
                          <Box sx={{ margin: '0 0 10px 0' }}>
                            <Typography component='label' htmlFor='email'>
                              Email Address
                            </Typography>
                          </Box>
                          <TextField
                            fullWidth
                            value={values.email}
                            onChange={handleChange}
                            name='email'
                            variant='outlined'
                            label='Enter email address...'
                            helperText={<ErrorMessage name='email' />}
                          />
                        </Box>
                        <Box>
                          <BtnLogin
                            type='submit'
                            variant='contained'
                            disabled={props.isSubmitting}
                            fullWidth
                          >
                            {props.isSubmitting ? 'Loading' : 'Send'}
                          </BtnLogin>
                        </Box>
                      </>
                    }
                    <BoxError>
                      {textError}
                    </BoxError>
                  </Form>
                );
              }}
            </Formik>
          </BoxForm>
        </DialogContent>
        <DialogActions>
          <Box>
            <BtnClose variant='outlined' onClick={handleDialogClose}>
              Close
            </BtnClose>
          </Box>
        </DialogActions>
      </DialogComponent>
    </div>
  );
};

export default SendEmailDialog;

SendEmailDialog.propTypes = {
  setOpenDialog: PropTypes.func,
  openDialog: PropTypes.bool,
  isSubmitting: PropTypes.func,
  values: PropTypes.object,
  handleChange: PropTypes.func,
};
