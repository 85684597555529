import PropTypes from 'prop-types';
import { ContainerError } from "./error.style";

const ErrorWidget = (props) => {
  return(
      <ContainerError>
        {props.message}
      </ContainerError>
  )
};

export default ErrorWidget;

ErrorWidget.propTypes = {
  message: PropTypes.string,
}