import { createTheme } from '@mui/material';

export let theme = createTheme({
  root: {
    padding: { xl: '0 192px', md: '0 64px' },
  },
  palette: {
    type: 'light',
    primary: {
      main: '#F5A623',
      color: '#FFFFFF'
    },
    secondary: {
      main: '#FFFFFF',
    },
    error: {
      main: '#F5A623',
    },
    success: {
      main: '#15C15D',
    },
    body: {
      main: '#000'
    }
  },
  typography: {
    fontFamily: [
      'Montserrat',
    ].join(','),
    h1: {
      // fontFamily: 'Montserrat',
      fontWeight: 700,
      fontSize: '1.8rem',
    },
    body2: {
      // fontFamily: 'Montserrat',
      fontWeight: 500,
      fontSize: '1.1rem',
      color: '#000000',
    },
    h2: {
      // fontFamily: 'Montserrat',
      fontSize: '1rem',
      fontWeight: 500,
    },
  },
  shape: {
    borderRadius: 4,
  },
  props: {
    MuiAppBar: {
      color: 'inherit',
    },
  },
  focused: {},
  disabled: {},
  error: {},
  overrides: {
    MuiAlert: {
      standardInfo: {
        backgroundColor: 'transparent',
      },
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: '1px solid #484646',
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottom: '1px solid #484646',
        },
        '&:focused:after': {
          borderBottom: '1px solid #484646',
        },
      },
    },
    MuiAppBar: {
      colorInherit: {
        backgroundColor: '#FFF',
        color: '#000',
      },
    },
  },
});

theme.components.MuiToolbar = {
  [theme.breakpoints.down('md')]: { alignItems: 'space-evenly' },
};

theme.typography.h2 = {
  [theme.breakpoints.down('lg')]: { fontSize: '22px' },
};
