import { styled } from "@mui/material/styles";
import { Typography, Grid } from "@mui/material";
import { theme } from "../../theme";
// import { theme } from "../../../theme";

export const HeaderText = styled(Typography)({
    margin: '20px 0',
    color: '#000000',
    opacity: '50%',
    fontFamily: 'Montserrat',
});

export const ContainerForm = styled(Grid)({
    maxWidth: 'calc(100% - 24px)',
    margin: '20px auto',
    [theme.breakpoints.up('sm')]: {
        maxWidth: '480px',
    }
  });
  
export const SecundaryText = styled(Typography)({
  boxSizing: 'border-box',
  padding: '0 0 30px 0',
  textAlign: 'center',
  fontSize: '.9rem',
  fontFamily: 'Montserrat',
  width: '100%',
});

export const SecundaryTextBold = styled(Typography)({
  boxSizing: 'border-box',
  padding: '0 0 30px 0',
  textAlign: 'center',
  fontSize: '1rem',
  fontWeight: 'bold',
  fontFamily: 'Montserrat',
  width: '100%',
});

export const ContainerInformation = styled(Grid)({
  display: 'flex',
  flexDirection:'row',
  alignItems: 'center',
  margin: '20px 0',
  background: 'rgba(234, 233, 233, 0.5)',
  padding: '10px 10px',
  borderRadius: '100px'
});

export const Informationtext = styled(Grid)({
  fontSize: '13px',
  marginLeft: '5px',
});
