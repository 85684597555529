import {
    Container,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
  } from '@mui/material';
  
  import { PageTitle, PageSubtitle, BoxTable } from './payments.page.styles.js';
  
  const AdminDocuments = () => {
    function createData( id, customerName, supplierAmount, PaymentDate, loremIpsumDo) {
      return { id, customerName, supplierAmount, PaymentDate, loremIpsumDo };
    }
  
    const rows = [
      createData(1, 'amogus', 'Pending Signature', 'amogus', 'amogus'),
      createData(2, 'amogus', 'Signature', 'amogus', 'amogus'),
      createData(3, 'amogus', 'Signature', 'amogus', 'amogus'),
      createData(4, 'amogus', 'Signature', 'amogus', 'amogus'),
      createData(5, 'amogus', 'Pending Signature', 'amogus', 'amogus'),
      createData(6, 'amogus', 'Pending Signature', 'amogus', 'amogus'),
      createData(7, 'amogus', 'Pending Signature', 'amogus', 'amogus'),
    ];
  
    return (
      <>
        <Container>
          <PageTitle>Payments</PageTitle>
          <PageSubtitle>View of my documents</PageSubtitle>
          <BoxTable>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Customer Name</TableCell>
                  <TableCell align='left'>Signature Status</TableCell>
                  <TableCell align='left'>Payment Date</TableCell>
                  <TableCell align='left'>Lorem Ipsum Do</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component='th' scope='row'>
                      {row.customerName}
                    </TableCell>
                    <TableCell align='left'>{row.supplierAmount}</TableCell>
                    <TableCell align='left'>{row.PaymentDate}</TableCell>
                    <TableCell align='left'>{row.loremIpsumDo}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </BoxTable>
        </Container>
      </>
    );
  };
  
  export default AdminDocuments;
  