import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import PaymentyPage from '../questionnaire-wizard/payment/payment.page';
import { DialogContent } from '@mui/material';

const UpdatePaymentMethod = ({
  openDialog,
  setOpenDialog,
  setLoader,
}) => {
  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  return (
    <Dialog
      open={openDialog}
      onClose={handleDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <PaymentyPage
          setLoader={setLoader}
          openDialog={openDialog}
          isEditMode
        />
      </DialogContent>
    </Dialog>
  );
}

export default UpdatePaymentMethod;

UpdatePaymentMethod.propTypes = {
  data: PropTypes.shape({
    businessId: PropTypes.number,
    name: PropTypes.string,
  }),
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func,
  onSuccess: PropTypes.func.isRequired,
  setLoader: PropTypes.func.isRequired,
};