import { useState } from 'react';
import CustomerService from '../../core/services/customer.service';
import CssBaseline from '@mui/material/CssBaseline';
import {
  Grid,
  Input,
  Divider,
  InputAdornment,
  DialogContent,
  FormControl,
  IconButton,
  InputLabel,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Information from '@mui/icons-material/InfoRounded';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import AppHelper from '../../core/helpers/AppHelper';
// import { useDispatch } from 'react-redux'
// import { changeStep } from '../../app/customerSlice';

import { useNavigate } from 'react-router-dom';

import logo from '../../assets/images/logo-fitradio.png';
import {
  ButtonCustom,
  Logo,
  HeaderText,
  Title,
  ContainerPage,
  SecundaryText,
  ModalContainer,
  HtmlTooltip,
  TitleToltip,
  TextToltip,
  ButtonEmail,
  BootstrapDialog,
  ModalError,
  MessageResponse,
} from './questionnarie.styles';

const QuestionnairePage = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(null);
  const [loader, setLoader] = useState(false);
  const customerService = new CustomerService();
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialValues = {
    email: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required('Email is required'),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      verifyEmail(values);
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const verifyEmail = async (data) => {
    setMessage('');
    setLoader(true);
    try {
      await customerService.validateEmail(data);
      setLoader(false);
      navigate('/questionnaire/wizard');
    } catch (error) {
      setMessage('Email does not exist.');
      AppHelper.checkError(error);
      setLoader(false);
    }
  };

  const bodyModal = (
    <ModalContainer>
      <Logo>
        <img alt='FitRadio' src={logo} />
      </Logo>
      <Title variant='h1' textAlign='center'>
        Enter your FITRADIO Email
      </Title>
      <SecundaryText>We will use your email address to verify if you are a FITRADIO PRO Subscriber</SecundaryText>
      <Grid textAlign='center'>
        <form onSubmit={formik.handleSubmit}>
          <FormControl sx={{ m: 1, width: '352px' }} variant='outlined'>
            <InputLabel htmlFor='email'>Enter email address...</InputLabel>
            <Input
              id='email'
              name='email'
              type='email'
              onChange={formik.handleChange}
              value={formik.values.email}
              endAdornment={
                <InputAdornment position='end'>
                  <HtmlTooltip
                    placement='right'
                    title={
                      <>
                        <TitleToltip>Find Your Account Info</TitleToltip>
                        <TextToltip>
                          {`Open the FITRADIO APP > Click on Profile > Edit Profile > Find the email address associated with your account`}
                        </TextToltip>
                      </>
                    }
                  >
                    <Information />
                  </HtmlTooltip>
                </InputAdornment>
              }
            />
            {formik.errors.email ? <ModalError>{formik.errors.email}</ModalError> : null}
            {message && <MessageResponse>{message}</MessageResponse>}
          </FormControl>
          <ButtonEmail type='submit'>Verify email</ButtonEmail>
        </form>
      </Grid>
    </ModalContainer>
  );

  return (
    <>
      <CssBaseline />
      <ContainerPage maxWidth='sm'>
        <Logo>
          <img alt='FitRadio' src={logo} />
        </Logo>
        <HeaderText variant='body2' textAlign='center'>
          Question 01/07
        </HeaderText>
        <Title variant='h1' textAlign='center'>
          Are you a current FITRADIO PRO subscriber?
        </Title>
        <Grid container spacing={1} textAlign='center'>
          <Grid xs={12} item>
            <ButtonCustom color='secondary' fullWidth variant='outlined' onClick={handleClickOpen}>
              Yes. Get 10% OFF Discount
            </ButtonCustom>
          </Grid>
          <Grid xs={12} item>
            <ButtonCustom fullWidth variant='outlined' href='https://fitradio.com/upgrade'>
              Become a Subscriber
            </ButtonCustom>
          </Grid>
          <Grid xs={12} item>
            <Divider sx={{ margin: '60px 0' }} />
          </Grid>
          <Grid xs={12} item>
            <ButtonCustom fullWidth variant='outlined' href='/questionnaire/wizard'>
              Not, Continue
            </ButtonCustom>
          </Grid>
        </Grid>
      </ContainerPage>
      <BootstrapDialog onClose={handleClose} aria-labelledby='customized-dialog-title' open={open} sx={{ zIndex: '3' }}>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>{bodyModal}</DialogContent>
      </BootstrapDialog>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 100 }}
        open={loader}
        onClick={handleClose}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
    </>
  );
};

export default QuestionnairePage;
