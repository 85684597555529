import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from '@stripe/react-stripe-js';
import { useSelector } from 'react-redux';
import { GridAction, CardInputWrapper, BtnSend } from '../questionnaire-wizard/payment/payment.style';
import { theme } from '../../theme';
import CustomerService from '../../core/services/customer.service';

const inputStyle = {
  iconColor: '#c4f0ff',
  color: theme.palette.primary.main,
  fontWeight: '500',
  fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
  fontSize: '22px',
  fontSmoothing: 'antialiased',
  ':-webkit-autofill': {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  '::placeholder': {
    color: 'rgba(0, 0, 0, 0.87)',
  },
};

const customerService = new CustomerService();

const UpdatePaymentMethodWidget = (props) => {
  const { user } = useSelector((state) => state.user);
  const stripe = useStripe();
  const elements = useElements();
  const [checkoutError, setCheckoutError] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const _subscribe = async (data) => {
    try {
      const dataRegistered = Object.preventExtensions(data);
      customerService.setAccessToken(user.token)
      await customerService.changePaymentMethod(dataRegistered);
    } catch (e) {
      props.reloadClienteSecret();
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const billinDetailsName = 'Juan';
    const cardElement = elements.getElement(CardNumberElement);
    const result = await stripe.confirmCardSetup(props.clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: { name: billinDetailsName },
      },
    });

    if (result.error) {
      setIsLoading(false);
      setCheckoutError(result.error.message);
      // props.reloadClienteSecret();
    } else {
      await _subscribe(result.setupIntent)
        .then(() => {
          props.openDialog(false);
          return window.location.reload();
        })
        .catch((e) => {
          setCheckoutError(e.message)
        })
        .finally(() => {
          setIsLoading(false)
        })
      // Use to localhost
      // console.log(result.setupIntent);
    }
  };

  const handleCardDetailsChange = (event) => {
    event.error ? setCheckoutError(event.error.message) : setCheckoutError();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography>Card number</Typography>
          <CardInputWrapper>
            <CardNumberElement options={{ style: { base: inputStyle } }} onChange={handleCardDetailsChange} />
          </CardInputWrapper>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: 'column',
          }}
        >
          <Typography>Expiration date</Typography>
          <CardInputWrapper>
            <CardExpiryElement options={{ style: { base: inputStyle } }} onChange={handleCardDetailsChange} />
          </CardInputWrapper>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: 'column',
          }}
        >
          <Typography>CVC</Typography>
          <CardInputWrapper>
            <CardCvcElement options={{ style: { base: inputStyle } }} onChange={handleCardDetailsChange} />
          </CardInputWrapper>
        </Grid>
      </Grid>
      <GridAction>
        <BtnSend type='submit' disabled={isLoading || !stripe}>
          {isLoading ? 'Loading' : 'Update'}
        </BtnSend>
      </GridAction>
      <Grid>
        <Typography color='red'>{checkoutError}</Typography>
      </Grid>
    </form>
  );
};

export default UpdatePaymentMethodWidget;

UpdatePaymentMethodWidget.propTypes = {
  clientSecret: PropTypes.string,
  reloadClienteSecret: PropTypes.func,
  openDialog: PropTypes.func,
  setReload: PropTypes.func,
};
