import React from 'react';
import { ThemeProvider } from '@mui/material/styles'
import ReactDOM from 'react-dom/client';
import './index.css';
import AppRoute from './core/routes/app.route';
import { theme } from './theme';
import { Provider } from 'react-redux';
import store from '../src/app/store';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
let persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <AppRoute />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
