import { styled } from '@mui/material/styles';
import { Box, Typography, Container, Button } from '@mui/material';
import { theme } from '../../../theme';

export const BoxTicket = styled(Box)({
    backgroundColor: theme.palette.primary.color,
    border: '1px solid #e8ecef',
    borderRadius: '4px',
    margin: '1em 0',
    boxShadow: '16px 24px rgb(0 0 0 / 4%)',
});

export const BoxTicketMain = styled(BoxTicket)({
    padding: '25px 20px'
});

export const BoxRowTop = styled(Box)({
    padding: '25px 20px'
});
export const BoxRowDown = styled(BoxRowTop)({
    background: 'rgba(234,233,233,0.25)'
});

export const TitleBox = styled(Typography)({
    margin: '0 0 5px 0',
    color: '#010101',
    fontSize: '32px',
    fontFamily: 'Bebas Neue',
    fontWeight: 'bold',
});

export const TitleBoxBootCamp = styled(TitleBox)({
    margin: '0 0 35px 0',
    fontSize: '48px',
});

const Label = styled(Typography)({
    color: '#272C30',
    fontFamily: 'Montserrat',
})

export const LabelName = styled(Label)({
    opacity: '0.75',
    fontSize: '14px',
    fontWeight: '500'
});

export const LabelTextTitle = styled(Label)({
    fontSize: '18px',
    fontWeight: '600'
});

export const ContainerAccount = styled(Container)({
    ['@media (min-width: 576px)'] : {
        maxWidth: '540px'
      },
    ['@media (min-width: 992px)'] : {
        maxWidth: '960px'
      },
    ['@media (min-width: 1200px)'] : {
        maxWidth: '1140px'
      },   
});

export const ButtonDownload = styled(Button)({
    fontFamily: 'Montserrat',
    color: '#010100',
    fontSize: '12px',
    fontWeight: 'bold',
    border: '1px solid #010100',
    borderRadius: '30px',
    padding: '7px 20px',
    letterSpacing: '1px',
    '& .MuiSvgIcon-root': {
        marginRight: '5px'
    }
});

const SpanStatus = styled('span')({
    fontFamily: 'Montserrat',
    fontSize: '12px',
    fontWeight: '500',
})

export const TitleTag = styled(SpanStatus)({
    textTransform: 'uppercase',
    letterSpacing: '1px'
});

export const PaymentStatus = styled(SpanStatus)({
    background: 'rgba(39, 174, 96, 0.12)',
    padding: '5px 8px',
    borderRadius: '1em',
    color: '#27AE60'
});

const Tags = styled(Typography)({
    fontSize: '14px',
    color: 'rgba(39,44,48,0.75)',
    fontFamily: 'Montserrat',
})

export const LinksTag = styled(Tags)({
    fontWeight: 'bold',
});

export const Tag = styled(Tags)({
    marginBottom: '0.5em',
});

export const ResultTag = styled(Typography)({
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom: '0.2em',
    color: 'rgb(39,44,48)',
});

export const ResultTagFinal = styled(ResultTag)({
    fontSize: '20px',
});