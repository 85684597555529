export default {
  formId: 'questionnaireForm',
  formField: {
    fullName: {
      name: 'fullName',
      label: 'Full Name *',
      requiredErrorMsg: 'Full name is required'
    },
    email: {
      name: 'email',
      label: 'Email *',
      requiredErrorMsg: 'Email is required'
    },
    phoneNumber: {
      name: 'phoneNumber',
      label: 'Phone Number *',
      requiredErrorMsg: 'Phone Number is required'
    },
    title: {
      name: 'title',
      label: 'Title',
      requiredErrorMsg: 'Title is required'
    },
    password: {
      name: 'password',
      label: 'Create a password *',
      requiredErrorMsg: 'Password is required'
    },
    password2: {
      name: 'password2',
      label: 'Re-enter your password *',
      requiredErrorMsg: 'Password is required'
    },
    verificationCode: {
      name: 'verificationCode',
      label: 'Verification code *',
      requiredErrorMsg: 'code is required'
    },
    packageCode : {
      name: 'packageCode',
      label: 'Do you have a Group Code',
      requiredErrorMsg: 'Do you have a Group Code is required'
    },
    businessName: {
      name: 'businessName',
      label: 'Business Name *',
      requiredErrorMsg: 'Business Name is required'
    },
    businessAddress: {
      name: 'businessAddress',
      label: 'Business Address *',
      requiredErrorMsg: 'Business Address required'
    },
    businessCity: {
      name: 'businessCity',
      label: 'City *',
      requiredErrorMsg: 'City is required'
    },
    businessState: {
      name: 'businessState',
      label: 'State *',
      requiredErrorMsg: 'State is required'
    },
    businessZipCode: {
      name: 'businessZipCode',
      label: 'Zip code *',
      requiredErrorMsg: 'Zip code is required'
    },
    businessType: {
      name: 'businessType',
      label: 'Business Type *',
      requiredErrorMsg: 'Business Type is required'
    },
    businessProviders: {
      name: 'businessProviders',
      label: 'Provider *',
      requiredErrorMsg: 'Provider Type is required'
    },
    isBgMusic: {
      name: 'isBgMusic',
      label: 'Music places *',
      requiredErrorMsg: 'Music places is required'
    },
    businessTotalMembers: {
      name: 'businessTotalMembers',
      label: 'Total Members *',
      requiredErrorMsg: 'Total Members is required'
    },
    businessNumberGroup: {
      name: 'businessNumberGroup',
      label: '# of Group Class Participant Capacity  *',
      requiredErrorMsg: 'Field is required'
    },
    businessClassesPerWeek: {
      name: 'businessClassesPerWeek',
      label: '# of Classes per Week *',
      requiredErrorMsg: 'Field is required'
    },
    businessSquareFootage: {
      name: 'businessSquareFootage',
      label: 'Square Footage of Gym',
      requiredErrorMsg: 'Field is required'
    },
  }
}
