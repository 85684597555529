import { at } from 'lodash';
import { useField } from 'formik';
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import NumberFormat from 'react-number-format';

const Input = styled(TextField)({
  marginBottom: '20px'
})

export default function InputFieldFormat(props) {
  const { ...rest } = props;
  const [field, meta] = useField(props);

  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return error;
    }
  }

  return (
		<NumberFormat
			customInput={Input}
			autoComplete="none"
			format="(###)###-####"
			error={meta.touched && meta.error && true}
			helperText={_renderHelperText()}
			{...field}
			{...rest}
		/>
  );
}
