
import { styled } from "@mui/system";
import { theme } from "../../../theme";

export const ContainerError = styled('div')({
  padding: '10px 0',
  textAlign: 'center',
  fontFamily: theme.typography.fontFamily,
  color: theme.palette.primary.main,
  fontSize: '1.2rem',
  width: '100%',
  fontWeight: 'bold'
})