import * as Yup from 'yup';
import questionnaireModel from './questionnaire.model';

const {
  formField: {
    fullName,
    email,
    phoneNumber,
    title,
    password,
    password2,
    verificationCode,
    businessName,
    businessAddress,
    businessCity,
    businessState,
    businessZipCode,
    businessType,
    businessProviders,
    businessTotalMembers,
    businessNumberGroup,
    businessClassesPerWeek,
    isBgMusic,
    businessSquareFootage,
    }
  } = questionnaireModel;

  export default [
    Yup.object().shape({
      [fullName.name]: Yup.string().required(`${fullName.requiredErrorMsg}`),
      [email.name]: Yup.string().email().required(`${email.requiredErrorMsg}`),
      [phoneNumber.name]: Yup.string().required( `${phoneNumber.requiredErrorMsg}`)
      // eslint-disable-next-line no-useless-escape
      .matches('^[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4}$', "The phone number must be this format: (123)123-1234")
      .typeError('you must specify a number'),
      [title.name]: Yup.string(),
      [password.name]: Yup.string().required(`${password.requiredErrorMsg}`)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        "Must contain 8 characters, one Uppercase, one Lowercase and one Number"
      ),
      [password2.name]: Yup.string()
      .oneOf([Yup.ref('password'), ''], 'Passwords must match')
      .required('Required'),
    }),
    Yup.object().shape({
      [verificationCode.name]: Yup.string().required(`${verificationCode.requiredErrorMsg}`)
      .matches('[0-9]{3}-[0-9]{3}', "The verification code must be this format: 123-123")
    }),
    Yup.object().shape({
      [businessName.name]: Yup.string().required(`${businessName.requiredErrorMsg}`),
      [businessAddress.name]: Yup.string().required(`${businessAddress.requiredErrorMsg}`),
      [businessCity.name]: Yup.object().required(`${businessCity.requiredErrorMsg}`).nullable(),
      [businessState.name]: Yup.object().required(`${businessState.requiredErrorMsg}`).nullable(),
      [businessZipCode.name]: Yup.string().matches(/([1-9][0-9]*)|0/,'Must be a number').required(`${businessZipCode.requiredErrorMsg}`).test('zipCode', 'Must be a valid zip code', value => {
        if (value) return /^[0-9]{5}(?:-[0-9]{4})?$/.test(value);
        return true;
      }),
      [businessType.name]: Yup.string().required(`${businessType.requiredErrorMsg}`),
    }),
    Yup.object().shape({
      [businessProviders.name]: Yup.array().min(1, 'You should select at least 1 provider').of(Yup.string().required()).required(`${businessProviders.requiredErrorMsg}`),
      [isBgMusic.name]: Yup.string().required('You should select at least 1 music place')
        .test('at-least-one', 'You should select at least 1 music place', (value) => {
          return !!value;
        })
        .required('Music place selection is required'),
    }),
    Yup.object().shape({
      [businessTotalMembers.name]: Yup.string().when(['isBgMusic'], {
        is: (isBgMusic) => {
          return isBgMusic !== '1';
        },
        then: Yup.string().required(`${businessTotalMembers.requiredErrorMsg}`)
          .matches(/^[1-9][0-9]*$/, 'Must be a number')
          .test('totalMembers', 'Must be a number greater than 0', (value) => {
            return /^[1-9][0-9]*$/.test(value) && value[0] !== '0';
          }),
        otherwise: Yup.string(),
      }),
      [businessNumberGroup.name]: Yup.string().when(['isBgMusic'], {
        is: (isBgMusic) => {
          return isBgMusic !== '1';
        },
        then: Yup.string()
          .required(`${businessNumberGroup.requiredErrorMsg}`)
          .matches(/^[1-9][0-9]*$/, 'Must be a number')
          .test('numberGroups', 'Must be a number greater than 0', (value) => {
            return /^[1-9][0-9]*$/.test(value) && value[0] !== '0';
          })
          .test({
            name: 'Greater',
            message: '# of Group Class Participant Capacity x # of Classes per Week cannot be greater than 15000',
            test: function (value) {
              if (value) {
                return parseInt(this.parent.businessClassesPerWeek * value) < 15000 && true;
              }
              return true;
            },
          }),
        otherwise: Yup.string(),
      }),
      [businessClassesPerWeek.name]: Yup.string().when(['isBgMusic'], {
        is: (isBgMusic) => {
          return isBgMusic !== '1';
        },
        then: Yup.string()
          .required(`${businessClassesPerWeek.requiredErrorMsg}`)
          .matches(/^[1-9][0-9]*$/, 'Must be a number')
          .test('classesPerWeek', 'Must be a number greater than 0', (value) => {
            return /^[1-9][0-9]*$/.test(value) && value[0] !== '0';
          })
          .test({
            name: 'Greater1',
            message: '# of Group Class Participant Capacity x # of Classes per Week cannot be greater than 15000',
            test: function (value) {
              if (value) {
                return parseInt(this.parent.businessNumberGroup * value) < 15000 && true;
              }
              return true;
            },
          }),
        otherwise: Yup.string(),
      }),
      [businessSquareFootage.name]: Yup.string()
        .required(`${businessSquareFootage.requiredErrorMsg}`)
        .matches(/^[1-9][0-9]*$/, 'Must be a number')
        .test('squareFootage', 'Must be a number greater than 0', (value) => {
          return /^[1-9][0-9]*$/.test(value) && value[0] !== '0';
        })
    }),
  ];

