import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import InputField from "../../../core/widgets/input.widget";
import InputFieldPassword from "../../../core/widgets/input.password.widget";
import { HeaderText, ContainerForm, SecundaryText } from "./form.styles";
import ErrorWidget from "../../../core/widgets/error/error.widget";
import InputFieldFormat from "../../../core/widgets/input.format.widget";

const RegisterForm = (props) => {
  const {
    formField: { fullName, email, phoneNumber, title, password, password2 },
  } = props;

  return (
    <>
      <HeaderText variant="body2" textAlign="center">
        Question 01/06
      </HeaderText>
      <Typography variant="h1" textAlign="center">
        To find the right license for your studio, please answer a few
        questions.
      </Typography>
      <ContainerForm container spacing={1}>
        <Grid item xs={12} md={12}>
          <InputField name={fullName.name} label={fullName.label} fullWidth />
        </Grid>
        <Grid item xs={12} md={12}>
          <InputField name={email.name} label={email.label} fullWidth />
        </Grid>
        <Grid item xs={12} md={12}>
          <InputFieldFormat
            format="(###)###-####"
            name={phoneNumber.name}
            label={phoneNumber.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <InputField name={title.name} label={title.label} fullWidth />
        </Grid>
        <SecundaryText variant="h2">
          Create a password to log into your licensing account
        </SecundaryText>
        <Grid item xs={12} md={12}>
          <InputFieldPassword
            name={password.name}
            label={password.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <InputFieldPassword
            name={password2.name}
            label={password2.label}
            fullWidth
          />
        </Grid>
        <ErrorWidget message={props.response} />
      </ContainerForm>
    </>
  );
};

export default RegisterForm;

RegisterForm.propTypes = {
  formField: PropTypes.object,
  response: PropTypes.string,
};
