import React from 'react';
import PropTypes from 'prop-types';
import { Field, ErrorMessage } from 'formik';
import styles from './style.css';
import { Box } from '@mui/material';

const error = {
  color: '#F5A623',
  margin: '10px 0',
  fontSize: '14px',
  fontFamily: 'Montserrat',
  fontWeight: 'bold',
  width: '100%',
  textAlign: 'center',
};

const RadioGroupWidget = (props) => {
  const { name, options, ...rest } = props;
  
  return (
    <>
      <Box
        sx={{
          display: 'block',
          flexDirection: {
            xs: 'column',
            sm: 'row',
          },
          width: '100%',
        }}
      >
        <Field name={name}>
          {({ field }) => {
            return options.map((option) => {
              return (
                <React.Fragment key={option.key}>
                  <article className={styles.article} style={(field.value === option.value) ? {backgroundColor: '#f5a623', color: 'white'} : {}}>
                    <input
                      type="radio"
                      id={option.value}
                      {...field}
                      {...rest}
                      value={option.value}
                      checked={field.value === option.value}
                    />
                    <label htmlFor={option.value}>{option.key}</label>
                  </article>
                </React.Fragment>
              );
            });
          }}
        </Field>
      </Box>
      <div style={error}>
        <ErrorMessage name={name} />
      </div>
    </>
  );
};

export default RadioGroupWidget;

RadioGroupWidget.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
  options: PropTypes.array,
  display: PropTypes.string,
};
