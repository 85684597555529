import React from 'react';
import { Typography, Grid, Container, Box, Link } from '@mui/material';

import { HomeStyles } from './home.styles';

import headerGuy from '../../assets/images/header-guy-headphones.png';

import bmiLogo from '../../assets/images/bmi-logo.svg';
import ascapLogo from '../../assets/images/ascap-logo.svg';
import sesacLogo from '../../assets/images/sesac-logo.svg';
import gmrLogo from '../../assets/images/gmr-logo.png';

import musicCoverage from '../../assets/images/music-coverage.svg';
import fullManagement from '../../assets/images/full-management.svg';
import easyPayments from '../../assets/images/easy-payments.svg';
import usaBased from '../../assets/images/usa-based.svg';

const HomePage = () => {
  const styles = HomeStyles();

  return (
    <>
    <div>
      <Box sx={styles.navbarWrapper}>
        <Container>
          <Container>
            <Box sx={styles.heroInner}>
              <Typography variant='h1' sx={styles.title}>
                Let FitRadio Manage Your <span> Music Licensing </span>
              </Typography>
              <Typography component='p' sx={styles.subTitle}>
                Obtain yearly licensing agreements from ASCAP, BMI, SESAC and GMR for
                the music played in your gym and fitness classes right from our
                website. Just fill out the questionnaire, and we&apos;ll
                calculate the rates for you.
              </Typography>
              <Link href='/register' sx={[styles.btn, styles.btnWhite]}>Get Started</Link>
            </Box>
            <Box item>
              <Box component='img' src={headerGuy} alt='Build-T' sx={styles.heroImg} />
            </Box>
            <Box sx={styles.logowall}>
              <a href='#' target='_blank'>
                <img src={bmiLogo} alt='' />
              </a>
              <a href='#' target='_blank'>
                <img src={ascapLogo} alt='' />
              </a>
              <a href='#' target='_blank'>
                <img src={sesacLogo} alt='' />
              </a>
              <a href='#' target='_blank'>
                <img src={gmrLogo} alt='' width={90} />
              </a>
            </Box>
            </Container>
        </Container>
      </Box>
      <Box sx={styles.licensingBenefits}>
        <Container>
          <Container>
            <Box sx={styles.titleWrap}>
              <div>
                <Typography sx={[styles.badgePrimary, styles.bpHome]}>BENEFITS</Typography>
                <Typography variant='h2' sx={styles.sectionTitle}>
                  Our licensing benefits
                </Typography>
              </div>
              <Box component='a' href='/register' sx={[styles.btn, styles.btnWhite]}>
                GET STARTED
              </Box>
            </Box>
            <Box sx={styles.licensingList}>
              <Box sx={styles.elementList}>
                <Box component='img' src={musicCoverage} alt='music-coverage' sx={styles.musicIcon} />
                <Typography variant='h4' sx={styles.licensingTitle}>
                  Music Coverage
                </Typography>
                <Typography component='p'>
                  Our Licensing covers the music played in your gym and group
                  fitness classes.
                </Typography>
              </Box>
              <Box sx={styles.elementList}>
                <Box component='img' src={fullManagement} alt='music-coverage' sx={styles.mgmIcon} />
                <Typography variant='h4' sx={styles.licensingTitle}>
                  Full Management
                </Typography>
                <Typography component='p'>
                  We’ll manage all of your licensing agreements, payments, and
                  reporting for you.
                </Typography>
              </Box>
              <Grid sx={styles.elementList}>
                <Box component='img' src={easyPayments} alt='music-coverage' sx={styles.paymentIcon} />
                <Typography variant='h4' sx={styles.licensingTitle}>
                  Easy Payments
                </Typography>
                <Typography component='p'>
                  You’ll only ever have to worry about one monthly payment.
                </Typography>
              </Grid>
              <Box sx={styles.elementList}>
                <Box component='img' src={usaBased} alt='music-coverage' sx={styles.usaBasedIcon} />
                <Typography variant='h4' sx={styles.licensingTitle}>
                  USA Based
                </Typography>
                <Typography component='p'>
                  Available for Gyms Located in the United States.
                </Typography>
              </Box>
            </Box>
            <Box sx={styles.licensingRecommended}>
              <p>For assistance, please contact <a href='mailto:silvia@fitradio.com'>silvia@fitradio.com</a></p>
            </Box>
          </Container>
        </Container>
      </Box>
{/*       <Box sx={styles.upgradeSection}>
        <Container>
          <Container>
            <Typography variant='h2' sx={styles.titleUpgrade} >Fitradio PRO members get <span >10% off</span>
            </Typography>
            <Typography sx={styles.subTitle}>FITRADIO subscribers recieve an additional 10% OFF     discount!</Typography>
            <Box sx={styles.wrapBtn}>
              <Link component='a' sx={[styles.btn, styles.btnWhite]}>GO PRO TODAY!</Link>
              <Link component='a' sx={[styles.btn, styles.btnPrimary]}>I&apos;m already a member</Link>
            </Box>
          </Container>
        </Container>
      </Box> */}
    </div>
    </>
  );
};

export default HomePage;
