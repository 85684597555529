import { serialize } from 'object-to-formdata';
import AppService from './AppService';

class CustomerService extends AppService {
  constructor() {
    super();
    this.pathStripe = this.getPath('stripe');
  }

  validateEmail(data) {
    const formData = serialize(data);
    return this.http.post(`/web/auth/verify-email`, formData);
  }

  create(data) {
    const formData = serialize(data);
    return this.http.post(`/web/auth/send-verification-code`, formData);
  }

  validateCode(data) {
    const formData = serialize(data);
    return this.http.post(`/web/auth/check-verification-code`, formData);
  }

  getproviders() {
    return this.http.get(`/web/providers`);
  }

  changePaymentMethod(data) {
    const formData = serialize(data);
    return this.http.post(`/web${this.pathStripe}/change-default-payment-method`, formData);
  }

}

export default CustomerService;
