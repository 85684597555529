import PropTypes from 'prop-types';
import { Grid, Typography} from '@mui/material';
import { HeaderText, ContainerForm, SecundaryText, SecundaryTextBold } from './form.styles';
import CheckboxGroupWidget from '../../../core/widgets/checkbox.group.widget';
import RadioGroupWidget from '../../../core/widgets/radio.group.widget';
import CustomerService from '../../../core/services/customer.service';
import { useEffect, useState } from 'react';
import AppHelper from '../../../core/helpers/AppHelper';
import { toString as _toString} from "lodash";

const optionsTwo = [
  {
    key: "FITNESS CLASSES & BACKGROUND MUSIC",
    value: "0"
  },
  {
    key: "BACKGROUND MUSIC",
    value: "1"
  }
]

const customerService = new CustomerService();

const SelectProviderForm = (props) => {

  const {
    formField: { businessProviders, isBgMusic },
    setCurrentProviders,
  } = props;

  const [providers, setProviders] = useState([]);

  const getProviders = async () => {
    try {
      const response = await customerService.getproviders();
      const providersFiltered = response.data.filter((provider) => provider.shortName !== "FITRADIO");
      const availableProviders = providersFiltered.map((provider) => {
        return {
          key: provider.shortName,
          value: _toString(provider.id)
        }
      }
      )
      setCurrentProviders(availableProviders);
      setProviders(availableProviders);
    } catch (error) {
      AppHelper.getError(error);
    }
  }

  useEffect(() => {
    getProviders();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HeaderText variant='body2' textAlign='center'>Question 04/07</HeaderText>
      <Typography variant="h1" textAlign='center'>
        {`Select which fee’s you would need for us to cover at this location`}
			</Typography>
      <ContainerForm container spacing={1}>
        <SecundaryTextBold>
          {`Select which PROs you need FITRADIO to cover`}
        </SecundaryTextBold>
        <SecundaryText>
          {`If your location has already obtained licensing for any of the PROs below, do not highlight them`}
        </SecundaryText>
        <Grid container spacing={1}>
          <CheckboxGroupWidget
            label={businessProviders.label}
            name={businessProviders.name}
            options={providers}
          />
        </Grid>
      </ContainerForm>
      <ContainerForm container spacing={1}>
        <SecundaryTextBold>
          {`Where do you play music in your gym?`}
        </SecundaryTextBold>
        <SecundaryText>
          {`Select all that apply`}
        </SecundaryText>
        <Grid container spacing={1}>
          <RadioGroupWidget
            name={isBgMusic.name}
            options={optionsTwo}
          />
        </Grid>
      </ContainerForm>
    </>
  )
};

export default SelectProviderForm;

SelectProviderForm.propTypes = {
  formField: PropTypes.object,
  setCurrentProviders: PropTypes.func,
};
