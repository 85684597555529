import React from 'react';
import HeaderWidget from './widgets/header/header.widget';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

const useAuth = () => {
  const { user } = useSelector(state => state.user);

  return user ? true : false;
}

const AccountLayout = () => {
  const auth = useAuth();
  return (
      auth ? 
      <>
        <HeaderWidget />
        <Outlet />
      </>
      : 
      <Navigate to='/' />
    
  );
};

export default AccountLayout;
