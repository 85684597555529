import { styled } from '@mui/material/styles';
import { Box, Button, Typography, Link } from '@mui/material';
import { theme } from '../../../theme';

export const BoxSettings = styled(Box)({
    backgroundColor: theme.palette.primary.color,
    border: '1px solid #e8ecef',
    boxShadow: '0 16px 24px rgb(0 0 0 / 4%)',
    borderRadius: '4px',
    padding: '20px 1.5em',
    position: 'relative',
    marginBottom: '20px',
});

export const BoxField = styled(Box)({
    marginTop: '2em',
    '& .MuiOutlinedInput-input':{
        fontFamily: 'Montserrat',
        fontSize: '16px',
    },
    '& .MuiInputLabel-root':{
        fontFamily: 'Montserrat',
        fontSize: '16px',
    }
});

export const TitleSettings = styled(Typography)({
    fontFamily: 'Bebas Neue',
    fontSize: '32px',
    fontWeight: 'bold',
    marginBottom: '0.8em'
});

export const SubtitleSettings = styled(Typography)({
    fontFamily: 'Montserrat',
    fontSize: '18px',
    fontWeight: 'bold',
});

export const LabelField = styled(Typography)({
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: 'bold',
    marginBottom: '0.75em'
});

export const LabelPsw = styled(Typography)({
    fontFamily: 'Montserrat',
    fontSize: '14px',
    marginTop: '1em',
    fontWeight: '500'
});

export const LinkReset = styled(Link)({
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: 'bold',
    textDecoration: 'none'
});

export const BtnSave = styled(Button)({
    display: 'flex',
    margin: '0 0 0 auto',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.color,
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    borderRadius: '2rem',
    fontSize: '14px',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    padding: '10px 40px',
    '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.color,
    }
  });

export const BoxRules = styled(Box)({
    background: 'rgba(234,233,233, 0.25)',
    padding: '2em 1.5em',
    borderRadius: '0.5em',
    fontSize: '14px',
    ['@media (min-width: 768px)']: { 
        marginLeft: '2.5em' 
    },
    '& ul':{
        paddingLeft: '0',
        '& li':{
            fontFamily: 'Montserrat',
            listStyleType: 'none'
        }
    }
});

export const TextRules = styled(Typography)({
    fontFamily: 'Montserrat',
    fontSize: '14px',
});