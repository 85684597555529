import { styled } from '@mui/material/styles';
import { Button, Grid, Container } from '@mui/material';
import { theme } from '../../theme';

export const BtnNext = styled(Button)(
  ({ theme }) => ({
    background: theme.palette.primary.main,
    color: '#FFFFFF',
    width: '132px',
    height: '54px',
    borderRadius:'8px',
    fontSize: '18px',
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    textTransform: 'none',
    '&:hover': {
      background: theme.palette.primary.main,
    },
    [theme.breakpoints.up('sm')]: {
      width: '221px',
    }
  })
)

export const BtnBack = styled(Button)({
  background: '#FFFFF',
  color: '#000000',
  width: '132px',
  height: '54px',
  borderRadius: '8px',
  fontSize: '18px',
  fontWeight: 'bold',
  fontFamily: 'Montserrat',
  border: '1px #5E646980 solid',
  textTransform: 'none',
  [theme.breakpoints.up('sm')]: {
    width: '221px',
  }
});

export const ContainerWizard = styled(Container)({
  width: 'calc(100% - 32px)',
  margin: '0 auto 150px',
  [theme.breakpoints.up('sm')]: {
    width: '700px',
  }
});
  
export const Logo = styled(Container)({
  textAlign: 'center',
  margin: '60px 0',
});
  
export const ActionsContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  boxSizing: 'border-box',
  padding: '40px 15px 5px 15px'
});

export const ActionsContainerModal = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%'
});

export const ContainerOption = styled(Grid)({
  display: 'flex',
  width: '100%',
  padding: '0 20px',
});

export const ContainerButtons = styled(Grid)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  margin:'20px 0'
});