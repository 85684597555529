import { createSlice } from "@reduxjs/toolkit";

export const appSlice = createSlice({
    name: 'app',
    initialState: {
      app: {
        openLogin: false,
        register: null
      },
    },
    reducers: {
      loginStatus: (state) => {
        state.app.openLogin = !state.app.openLogin;
      },
      addRegister: (state, action) => {
        state.app.register = action.payload;
      },
      removeRegister: (state) =>  {
        state.app.register = null;
      },
    }
})

export const { loginStatus, addRegister, removeRegister } = appSlice.actions;

export default appSlice.reducer;
