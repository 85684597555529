
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { Autocomplete, fieldToTextField } from 'formik-mui';
import { useEffect } from 'react';

const FormikAutocomplete = ({ textFieldProps, ...props }) => {
  const {
    form: {initialValues, setTouched, setFieldValue, values },
  } = props
  const { getValueChild, isBusinessStateChanged, child, ...rest} = props;
  const { error, helperText, ...field } = fieldToTextField(rest)
  const { name, label } = field;

  const handleChange = (value) => {
    setFieldValue(name, value !== null ? value : initialValues[name]);
    if(!child) getValueChild(value);
  }

  useEffect(() => {
    if(child && isBusinessStateChanged) setFieldValue(name, null); 
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  , [props.options])

  const allOptions = values[name] ? [...props.options, values[name]] : props.options;

  return (
    <Autocomplete
      {...rest}
      {...field}
      options={allOptions}
      onChange={(_, value) => handleChange(value) }
      onBlur={() => setTouched({ [name]: true })}
      getOptionLabel={(option) => option.label || ""}
      isOptionEqualToValue={(option, value) => option.value === value.value || value.value === null }
      filterSelectedOptions
      renderInput={props => (
        <TextField
          {...props}
          {...textFieldProps}
          helperText={helperText}
          error={error}
          label={label} 
          sx={{marginTop: '0'}}
        />
      )}
    />
  )
}

export default FormikAutocomplete;

FormikAutocomplete.propTypes = {
  form: PropTypes.object,
  textFieldProps: PropTypes.object,
  getValueChild: PropTypes.func || null,
  child: PropTypes.bool || false,
  options: PropTypes.array || null,
  isBusinessStateChanged: PropTypes.bool || false,
};
