import { Container, Typography, Grid, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { theme } from '../../../theme';

export const ContainerSummary = styled(Container)({
  margin: '0 auto',
});

export const TitleSummary = styled(Typography)({
  color: '#000000',
  margin: '10px 0',
});

export const TopSummary = styled(Typography)({
  color: theme.palette.primary.main,
  fontFamily: 'Montserrat',
  textAlign: 'center',
  fontWeight: 'bold',
  fontSize: '20px',
  paddingBottom: '30px'
});

export const GridAction = styled(Grid)({
  fontFamily: 'Montserrat',
  margin: '30px 0 60px',
});

export const ButtonCheckout = styled(Button)({
  background: theme.palette.primary.main,
  color: '#ffffff',
  fontSize: '18px',
  borderRadius: '8px',
  textTransform: 'none',
  fontWeight: 'bold',
  fontFamily: 'Montserrat',
  height: '54px',
  '&:hover': {
    background: theme.palette.primary.main,
  },
});


export const CardInputWrapper = styled('div')({
  borderRadius: 8,
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: theme.palette.primary.main,
  padding: '12px',
  margin: '10px 0'
});

export const BtnSend = styled(Button)({
  background: theme.palette.primary.main,
  color: '#ffffff',
  fontSize: '18px',
  borderRadius: '8px',
  textTransform: 'none',
  fontWeight: 'bold',
  fontFamily: 'Montserrat',
  height: '51px',
  width: '100%',
  '&:hover': {
    background: theme.palette.primary.main,
  },
})
