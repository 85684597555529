import { styled } from "@mui/material/styles";
import { Box, Link, Button, Typography } from "@mui/material";
import { theme } from "../../theme";

export const Title = styled(Typography)({
  fontFamily: 'Bebas Neue',
  fontSize: "56px",
  color: theme.palette.body.main,
  fontWeight: 'bold',
  margin: '0.5em 0'
});

export const BoxForm = styled(Box)({
  width: '100%',
  textAlign: "left",
  "& label": {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: "14px",
    textAlign: "left",
  },
});

export const BoxPswLabels = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  margin: "20px 0 10px 0",
  "& label + label": {
    color: theme.palette.primary.main,
  },
});


export const BtnLink = styled(Link)({
  textDecoration: "none",
  color: theme.palette.primary.main,
});

export const BtnLogin = styled(Button)({
  color: theme.palette.primary.color,
  backgroundColor: theme.palette.primary.main,
  marginTop: "30px",
  marginBottom: "30px",
  borderRadius: "20px",
  padding: "10px",
  fontFamily: "Montserrat",
  fontWeight: "bold",
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
});

export const BoxError = styled(Typography)({
  textAlign: "center",
  padding: "20px 0",
  color: theme.palette.primary.main,
  fontSize: "1.2rem",
  fontWeight: "bold",
});
