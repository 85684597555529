import { forwardRef } from 'react';
import {
  Box,
  ListItem,
  List,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import {
  FileCopy,
  Settings,
  AddShoppingCart,
/*   Help */
} from '@mui/icons-material';
import { Title, Subtitle, ElementNavbar } from '../assets/styles/licensing.styles';
import { NavLink } from 'react-router-dom';

// eslint-disable-next-line react/display-name
const CustomNavLink = forwardRef((props, ref) => (
  <NavLink
    ref={ref}
    {...props}
    className={({ isActive }) =>
      // eslint-disable-next-line react/prop-types
      isActive ? props.className + ' Mui-selected' : props.className
    }
    end
  />
));

export const Licensing = () => {
  return (
    <>
      <Box>
        <Title>Licensing</Title>
        <Subtitle>Update and manage your account</Subtitle>
        <List>
          <ListItem>
            <ElementNavbar
              component={CustomNavLink}
              to='/account'
              style={({ isActive }) => ({
                color: isActive ? '#F5A623' : 'rgba(39, 44, 48, 0.75)',
              })}
            >
              <FileCopy />
              <ListItemText primary='Agreements' sx={{ paddingLeft: '1em' }} />
            </ElementNavbar>
          </ListItem>
          <ListItem>
            <ListItemButton
              component={CustomNavLink}
              to='/account/settings'
              style={({ isActive }) => ({
                color: isActive ? '#F5A623' : 'rgba(39, 44, 48, 0.75)',
              })}
            >
              <Settings />
              <ListItemText primary='Settings' sx={{ paddingLeft: '1em' }} />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              component={CustomNavLink}
              to='/account/membership'
              style={({ isActive }) => ({
                color: isActive ? '#F5A623' : 'rgba(39, 44, 48, 0.75)',
              })}
            >
              <Settings />
              <ListItemText primary='Membership' sx={{ paddingLeft: '1em' }} />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              component={CustomNavLink}
              to='/account/add-product'
              style={({ isActive }) => ({
                color: isActive ? '#F5A623' : 'rgba(39, 44, 48, 0.75)',
              })}
            >
              <AddShoppingCart />
              <ListItemText primary='Add Product' sx={{ paddingLeft: '1em' }} />
            </ListItemButton>
          </ListItem>
          {/*<ListItem>
            <ListItemButton
              component={CustomNavLink}
              to='/account/contactus'
              style={({ isActive }) => ({
                color: isActive ? '#F5A623' : 'rgba(39, 44, 48, 0.75)',
              })}
            >
              <Help />
              <ListItemText primary='Contact Us' sx={{ paddingLeft: '1em' }} />
            </ListItemButton>
          </ListItem> */}
        </List>
      </Box>
    </>
  );
};
