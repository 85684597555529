import { useEffect, useState } from 'react';
import { Container, Grid, Box, Typography } from '@mui/material';
import { Licensing } from './licensing';
import { Download, FileCopy } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import AccountService from '../../core/services/account.service';
import AgreementService from '../../core/services/agreement.service';
import LoaderWidget from '../../core/widgets/loader/loader.widget';

import {
  BoxAgreements,
  TitleAgreements,
  BoxDocument,
  BtnDownload,
  BtnAllDownload
} from '../assets/styles/agreements.styles';

const AgreementsPage = () => {
  const [agreements, setAgreements] = useState([])
  const [loader, setLoader] = useState(false);
  const { user } = useSelector((state) => state.user);
  const accountService = new AccountService();
  const agreementService =  new AgreementService();

  const getAgreements = async () => {
    setLoader(true);
    try {
      accountService.setAccessToken(user.token)
      const response = await accountService.getAgreements();
      setAgreements(response.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  }

  const downloadAgreement = async (id, name) => {
    try {
      agreementService.setAccessToken(user.token);
      const response = await agreementService.download(id);

      let url = window.URL.createObjectURL(new Blob([response.data]));
      let file = document.createElement('a');
      file.href = url;
      file.download = `${name.toLowerCase()}_agreement.pdf`;
      file.click();
    } catch (error) {
      setLoader(false);
    }
  }

  const downloadCoverage = async (id, name) => {
    try {
      agreementService.setAccessToken(user.token);
      const response = await agreementService.downloadCover(id);

      let url = window.URL.createObjectURL(new Blob([response.data]));
      let file = document.createElement('a');
      file.href = url;
      file.download = `${name.toLowerCase()}_coverage.pdf`;
      file.click();
    } catch (error) {
      setLoader(false);
    }
  }

  const downloadAllAgreements = async () => {
    try {
      agreementService.setAccessToken(user.token);
      const response = await agreementService.downloadAl();

      let url = window.URL.createObjectURL(new Blob([response.data]));
      let file = document.createElement('a');
      file.href = url;
      file.download = `agreements.zip`;
      file.click();
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getAgreements();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Container>
        <Grid container spacing={2} sx={{ marginTop: '2em' }}>
          <Grid item xs={12} md={3}>
            <Licensing />
          </Grid>
          <Grid item xs={12} md={9}>
            <BoxAgreements>
              <TitleAgreements>Agreements</TitleAgreements>
              {agreements?.map((agreement, i) => (
                <BoxDocument key={i}>
                  <Box>
                    <FileCopy />
                    <Typography>{agreement.name}</Typography>
                  </Box>
                  <Box>
                  {
                    agreement.agreementLink &&
                    <BtnDownload onClick={() => downloadAgreement(agreement.agreementId, agreement.shortName)}>
                      <Download />
                      <Typography>Download Agreement</Typography>
                    </BtnDownload>
                  }
                  {
                    agreement.coverageLink &&
                    <BtnDownload onClick={() => downloadCoverage(agreement.agreementId, agreement.shortName)}>
                      <Download />
                      <Typography>Download Coverage</Typography>
                    </BtnDownload>
                  }
                  </Box>
                </BoxDocument>
              ))}
              <BtnAllDownload onClick={downloadAllAgreements}>
                <Download />
                <Box component='span'>Download All</Box>
              </BtnAllDownload>
            </BoxAgreements>
          </Grid>
        </Grid>
        <LoaderWidget loader={loader} />
      </Container>
    </>
  );
};

export default AgreementsPage;
