import { Container, Grid, Box, TextField, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from 'yup';
import { useSelector } from "react-redux";
import { Licensing } from "../agreements/licensing";
import AuthService from "../../core/services/auth.service";
import AccountService from "../../core/services/account.service";
import InputFieldPassword from "../../core/widgets/input.password.widget";
import LoaderWidget from "../../core/widgets/loader/loader.widget";

import {
  BoxSettings,
  TitleSettings,
  SubtitleSettings,
  BoxField,
  LabelField,
  LabelPsw,
  BtnSave,
  BoxRules,
  TextRules,
} from "../assets/styles/settings.styles";

const SettingsPage = () => {
  const { user } = useSelector((state) => state.user);
  const authService = new AuthService();
  const accountService = new AccountService();
  const [customer, setCustomer] = useState({
    fullName: "",
    email: "",
    title: "",
    phoneNumber: "",
    password: "",
    newPassword: "",
    newPassword2: "",
    name: "",
    type: "",
    city: "",
    state: "",
    zipcode: "",
    totalMembers: "",
    numberGroup: "",
    classesPerWeek: "",
  });
  const [loader, setLoader] = useState(false);

  const getCustomer = async () => {
    setLoader(true);
    try {
      authService.setAccessToken(user.token);
      accountService.setAccessToken(user.token);
      const response = await accountService.getAccount();
      setCustomer({
        fullName: response.data.info.fullName,
        email: response.data.info.email,
        phoneNumber: response.data.info.phoneNumber,
        title: response.data.info.title,
        name: response.data.business.name,
        state: response.data.business.state,
        type: response.data.business.type,
        city: response.data.business.city,
        address: response.data.business.address,
        zipcode: response.data.business.zipCode,
        totalMembers: response.data.member.totalMembers,
        numberGroup: response.data.member.numberGroup,
        classesPerWeek: response.data.member.classesPerWeek,
      });
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  const handleChange = () => {
    setCustomer(...customer);
  };

  const recoverPassword = async () => {
    try {
      authService.setAccessToken(user.token);
      await authService.recoverPassword({
        email: customer.email,
        token: user.token,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const _handleSubmit = async (val) => {
    setLoader(true);
    try {
      authService.setAccessToken(user.token);
      await authService.changePassword(val);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  }

  useEffect(() => {
    getCustomer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container>
        <Grid container spacing={2} sx={{ marginTop: "2em" }}>
          <Grid item xs={12} md={3}>
            <Licensing />
          </Grid>
          <Grid item xs={12} md={9}>
            <BoxSettings>
              <TitleSettings>Account Settings</TitleSettings>
              <Box
                sx={{
                  borderBottom: "1px solid rgba(0,0,0,0.15)",
                  paddingBottom: "2.5em",
                }}
              >
                <SubtitleSettings>My Information</SubtitleSettings>
                <Grid container spacing={4}>
                  <Grid item md={6}>
                    <BoxField>
                      <LabelField>Full Name</LabelField>
                      <TextField
                        label="Full Name"
                        name={customer.fullName}
                        value={customer.fullName}
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth
                        onChange={handleChange}
                      />
                    </BoxField>
                  </Grid>
                  <Grid item md={6}>
                    <BoxField>
                      <LabelField>Email Address</LabelField>
                      <TextField
                        label="Email Address"
                        name={customer.email}
                        value={customer.email}
                        onChange={handleChange}
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth
                      />
                    </BoxField>
                  </Grid>
                  <Grid item md={6}>
                    <BoxField>
                      <LabelField>Title (optional)</LabelField>
                      <TextField
                        label="Title"
                        name={customer.title}
                        value={customer.title}
                        onChange={handleChange}
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth
                      />
                    </BoxField>
                  </Grid>
                  <Grid item md={6}>
                    <BoxField>
                      <LabelField>Phone Number</LabelField>
                      <TextField
                        label="Phone Number"
                        value={customer.phoneNumber}
                        name={customer.phoneNumber}
                        onChange={handleChange}
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth
                      />
                    </BoxField>
                  </Grid>
                </Grid>
              </Box>
              <Box
                sx={{
                  borderBottom: "1px solid rgba(0,0,0,0.15)",
                  paddingBottom: "2.5em",
                  paddingTop: "2em",
                }}
              >
                <SubtitleSettings>Change Password</SubtitleSettings>

                <Formik
                  initialValues={{
                    oldPassword: '',
                    password: '',
                    passwordConfirmation: '',
                  }}
                  onSubmit={(values) => _handleSubmit(values)}
                  validationSchema= {
                    Yup.object({
                      oldPassword: Yup.string().required('Current password is required')
                      .matches(
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
                        "Must contain 8 characters, one Uppercase, one Lowercase and one Number"
                      ),
                      password: Yup.string().required('New password is required')
                      .matches(
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
                        "Must contain 8 characters, one Uppercase, one Lowercase and one Number"
                      ),
                      passwordConfirmation: Yup.string()
                      .oneOf([Yup.ref('password'), ''], 'New password and confirm new password  must match')
                      .required('Confirm new password is required'),
                    })
                  }
                >
                  <Form>
                    <Grid container spacing={4}>
                      <Grid item md={6}>
                        <BoxField>
                          <LabelField>Current Password</LabelField>
                          <InputFieldPassword
                            name="oldPassword"
                            fullWidth
                            label="Current Password"
                          />
                        </BoxField>
                        <BoxField>
                          <LabelField>New Password</LabelField>
                          <InputFieldPassword
                            name="password"
                            fullWidth
                            label="New Password"
                          />
                        </BoxField>
                        <BoxField>
                          <LabelField>Confirm New Password</LabelField>
                          <InputFieldPassword
                            name="passwordConfirmation"
                            fullWidth
                            label="Confirm New Password"
                          />
                        </BoxField>
                        <LabelPsw>
                          Can&apos;t remember your current password?
                        </LabelPsw>
                        <Button onClick={recoverPassword}>Reset Password</Button>
                      </Grid>
                      <Grid item md={6}>
                        <BoxRules>
                          <LabelField>Rules for new password</LabelField>
                          <TextRules>
                            To create a new password, you have to meet all of
                            the following requirements:
                          </TextRules>
                          <Box component="ul">
                            <li>• Minimum 8 character</li>
                            <li>• At least one uppercase letter</li>
                            <li>• At least one number</li>
                            <li>• Can’t be the same as a previous</li>
                          </Box>
                        </BoxRules>
                      </Grid>
                      <BtnSave type='submit'>Save changes</BtnSave>
                    </Grid>
                  </Form>
                </Formik>
                
              </Box>
              <Box
                sx={{
                  borderBottom: "1px solid rgba(0,0,0,0.15)",
                  paddingBottom: "2.5em",
                  paddingTop: "2em",
                }}
              >
                <SubtitleSettings>Bussiness Information</SubtitleSettings>
                <Grid container spacing={4}>
                  <Grid item md={4}>
                    <BoxField>
                      <LabelField>Gym Name</LabelField>
                      <TextField
                        label="Gym Name"
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth
                        name="name"
                        value={customer.name}
                      />
                    </BoxField>
                  </Grid>
                  <Grid item md={4}>
                    <BoxField>
                      <LabelField>Business Type</LabelField>
                      <TextField
                        label="Business Type"
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth
                        name="type"
                        value={customer.type}
                      />
                    </BoxField>
                  </Grid>
                  <Grid item md={4}>
                    <BoxField>
                      <LabelField>Location Address</LabelField>
                      <TextField
                        label="Location Address"
                        defaultValue="299 SW 28 ST"
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth
                        value={customer.address}
                      />
                    </BoxField>
                  </Grid>
                  <Grid item md={4}>
                    <BoxField sx={{ marginTop: "0em" }}>
                      <LabelField>City</LabelField>
                      <TextField
                        label="City"
                        defaultValue="Miami"
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth
                        name="city"
                        value={customer.city}
                      />
                    </BoxField>
                  </Grid>
                  <Grid item md={4}>
                    <BoxField sx={{ marginTop: "0em" }}>
                      <LabelField>State</LabelField>
                      <TextField
                        label="State"
                        defaultValue="FL"
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth
                        name="state"
                        value={customer.state}
                      />
                    </BoxField>
                  </Grid>
                  <Grid item md={4}>
                    <BoxField sx={{ marginTop: "0em" }}>
                      <LabelField>Zip</LabelField>
                      <TextField
                        label="Zip"
                        defaultValue="33314"
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth
                        name="zipcode"
                        value={customer.zipcode}
                      />
                    </BoxField>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ paddingTop: "2em" }}>
                <SubtitleSettings>Member Details</SubtitleSettings>
                <Grid container spacing={4}>
                  <Grid item md={6}>
                    <BoxField>
                      <LabelField>Total Members</LabelField>
                      <TextField
                        label="Total Members"
                        defaultValue="102"
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth
                        name="totalMembers"
                        value={customer.totalMembers}
                      />
                    </BoxField>
                  </Grid>
                  <Grid item md={6}>
                    <BoxField>
                      <LabelField># Of Classes Per Week</LabelField>
                      <TextField
                        label="# Of Classes Per Week"
                        defaultValue="14"
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth
                        name="numberGroup"
                        value={customer.numberGroup}
                      />
                    </BoxField>
                  </Grid>
                  <Grid item MD={6}>
                    <BoxField>
                      <LabelField>
                        # of Group Class Participant Capacity{" "}
                      </LabelField>
                      <TextField
                        label="# of Group Class Participant Capacity "
                        defaultValue="40"
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth
                        name="classesPerWeek"
                        value={customer.classesPerWeek}
                      />
                    </BoxField>
                  </Grid>
                </Grid>
              </Box>
            </BoxSettings>
          </Grid>
        </Grid>
        <LoaderWidget loader={loader} />
      </Container>
    </>
  );
};

export default SettingsPage;
