import { Container, Typography, Grid, Button } from '@mui/material';
import { styled} from  '@mui/material/styles';
import { theme } from '../../../theme';

export const ContainerSummary = styled(Container)({
  width: '100%',
  margin: '0 auto',
  [theme.breakpoints.up('sm')]: {
    width: '580px',
  }
});

export const TitleSummary = styled(Typography)({
  color: '#000000',
  margin: '10px 0'
});

export const TopSummary = styled(Typography)({
  color: theme.palette.primary.main,
  fontFamily: 'Montserrat',
  textAlign: 'center',
  fontWeight: 'bold',
  fontSize: '18px'
});

export const GridSummary = styled(Grid)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '15px',
  fontFamily: 'Montserrat',
});

export const GridSummaryText = styled(Typography)({
  fontFamily: 'Montserrat',
  color: '#272C30'
});

export const GridSummaryTextTitle = styled(Typography)({
  fontFamily: 'Montserrat',
  color: '#272C30',
  textTransform: 'uppercase',
  fontWeight: 'bold'
});

export const GridSummarySecundary = styled(Grid)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '2px 15px',
  fontFamily: 'Montserrat',
});

export const GridSummaryTextSecundary = styled(Typography)({
  fontFamily: 'Montserrat',
  color: '#272C30',
  fontSize: '14px', 
});


export const GridSummaryTextCode = styled(Typography)({
  fontFamily: 'Montserrat',
  color: '#F5A623',
  fontSize: '14px', 
});

export const GridSummaryTotal = styled(Grid)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '15px',
  fontFamily: 'Montserrat',
  background:'rgba(234, 233, 233, 0.5)', // 
  margin: '20px 0'
});

export const GridSummaryTotalTitle = styled(Typography)({
  fontFamily: 'Montserrat',
  color: '#272C30',
  fontSize: '22px',
  fontWeight: 'bold'
});

export const GridSummaryTotalText = styled(Typography)({
  fontFamily: 'Montserrat',
  color: '#272C30',
  fontSize: '12px',
  fontWeight: '400'
});

export const Discount = styled(Typography)({
  fontFamily: 'Montserrat',
  color: '#272C30',
  fontSize: '16px',
  opacity: '50%',
  textDecoration: 'line-through',
  fontWeight: '400',
  margin: '0 10px'
});

export const MountTotal = styled(Typography)({
  fontFamily: 'Montserrat',
  color: '#272C30',
  fontSize: '22px',
  fontWeight: '600'
});

export const Period = styled(Typography)({
  fontFamily: 'Montserrat',
  color: '#272C30',
  fontSize: '12px',
  fontWeight: '400',
  textAlign: 'right', 
});

export const GridValidation = styled(Grid)({
  fontFamily: 'Montserrat',
  margin: '30px 0'
});

export const GridAction = styled(Grid)({
  fontFamily: 'Montserrat',
  margin: '30px 0 60px'
});

export const GridPromo = styled(Grid)({
  fontFamily: 'Montserrat',
  margin: '30px 0',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
});

export const ButtonCheckout = styled(Button)({
  background: theme.palette.primary.main,
  color: '#ffffff',
  fontSize: '18px',
  borderRadius: '8px',
  textTransform: 'none',
  fontWeight: 'bold',
  fontFamily: 'Montserrat',
  height: '54px',
  '&:hover' : {
    background: theme.palette.primary.main,
  }
});

export const GridTotal = styled(Grid)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center'
});