import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import InputField from '../../../core/widgets/input.widget';
import { HeaderText, ContainerForm } from './form.styles';

const InformationForm = (props) => {
  const {
    formField: {
      businessTotalMembers,
      businessNumberGroup,
      businessClassesPerWeek,
      businessSquareFootage,
    },
    defaultValues,
  } = props;

  const { isBgMusic } = defaultValues;

  return (
    <>
      <HeaderText variant='body2' textAlign='center'>Question 05/06</HeaderText>
      <Typography variant="h1" textAlign='center'>
        Provide more information about your location to help us calculate your PRO licensing fees
      </Typography>
      <ContainerForm container spacing={1}>
        {isBgMusic !=='1' && (
          <Grid item xs={12} md={12}>
            <Grid container>
              <Grid item xs={12} md={12}>
                <InputField name={businessTotalMembers.name} label={businessTotalMembers.label} fullWidth/>
              </Grid>
              <Grid item xs={12} md={12}>
                <InputField name={businessNumberGroup.name} label={businessNumberGroup.label} fullWidth/>
              </Grid>
              <Grid item xs={12} md={12}>
                <InputField name={businessClassesPerWeek.name} label={businessClassesPerWeek.label} fullWidth/>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12} md={12}>
          <InputField name={businessSquareFootage.name} label={businessSquareFootage.label} fullWidth/>
        </Grid>
      </ContainerForm>
    </>
  )
};

export default InformationForm;

InformationForm.propTypes = {
  formField: PropTypes.shape({
    businessTotalMembers: PropTypes.object,
    businessNumberGroup: PropTypes.object,
    businessClassesPerWeek: PropTypes.object,
    businessSquareFootage: PropTypes.object,
  }),
  defaultValues: PropTypes.shape({
    businessProviders: PropTypes.arrayOf(PropTypes.string),
    isBgMusic: PropTypes.string,
  }),
};
