import { Route, Routes } from 'react-router-dom';

import AccountPage from '../../pages/account/account.page';
import AdminDocuments from '../../pages/admin/admin-documents';
import AdminHome from '../../pages/home/admin-home.page';
import HomePage from '../../pages/home/home.page';
import PasswordReset from '../../pages/home/password-reset-success.page';
import ResetPassword from '../../pages/home/reset-password.page';
import StripePage from '../../pages/home/stripe.page';
import Payments from '../../pages/payments/payments.page.js';
import QuestionnaireWizardPage
  from '../../pages/questionnaire-wizard/questionnaire.wizard.page';
import QuestionnairePage from '../../pages/questionnaire/questionnaire.page';
import WebLayout from '../layouts/web.layout';

export const WebRoute = () => (
  <Routes>
    <Route element={<WebLayout />}>
      <Route index element={<HomePage />} />
      <Route element={<QuestionnairePage />} path='/questionnaire' />
      <Route element={<QuestionnaireWizardPage />} path='/register' />
      <Route element={<StripePage />} path='/stripe' />
      <Route exact path='/dashboard/account' index element={<AccountPage />} />
      <Route exact path='/adminhome' index element={<AdminHome />} />
      <Route exact path='/admin/documents' index element={<AdminDocuments />} />
      <Route exact path='/payments' index element={<Payments />} />
      <Route exact path='/reset-password' index element={<ResetPassword />} />
      <Route exact path='/password-reset' index element={<PasswordReset />} />
    </Route>
  </Routes>
);
