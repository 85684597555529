import React from 'react';
import { Container, Grid } from '@mui/material';
import {
  BoxAgreements,
} from '../assets/styles/agreements.styles';
import { Licensing } from '../agreements/licensing';
import SelectProviderForm from './selectProviderForm';
const AddProductPage = () => {
  return (
    <>
      <Container>
        <Grid container spacing={2} sx={{ marginTop: '2em' }}>
          <Grid item xs={12} md={3}>
            <Licensing />
          </Grid>
          <Grid item xs={12} md={9}>
            <BoxAgreements>
              <SelectProviderForm />
            </BoxAgreements>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default AddProductPage;
