import questionnaireModel from "./questionnaire.model";

const {
  formField: {
    fullName,
    email,
    phoneNumber,
    title,
    password,
    password2,
    verificationCode,
    packageCode,
    businessName,
    businessAddress,
    businessCity,
    businessState,
    businessZipCode,
    businessType,
    businessProviders,
    businessTotalMembers,
    businessNumberGroup,
    businessClassesPerWeek,
    isBgMusic,
    businessSquareFootage,
  }
} = questionnaireModel;

export default {
  [fullName.name]: '',
  [email.name]: '',
  [phoneNumber.name]: '',
  [title.name]: '',
  [password.name]: '',
  [password2.name]: '',
  [verificationCode.name]: '',
  [packageCode.name]: '',
  [businessName.name]: '',
  [businessAddress.name]: '',
  [businessCity.name]: null,
  [businessState.name]: null,
  [businessZipCode.name]: '',
  [businessType.name]: '',
  [businessProviders.name]: [],
  [businessTotalMembers.name]: '',
  [businessNumberGroup.name]: '',
  [businessClassesPerWeek.name]: '',
  [isBgMusic.name]: '',
  [businessSquareFootage.name]: '',
};
