import { styled } from '@mui/material/styles';
import { theme } from '../../theme';
import { Button, Typography, Grid, Container, Dialog } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

export const ButtonCustom = styled(Button)(() => ({
  color: 'rgba(0,0,0,.75)',
  textTransform: 'none',
  fontFamily: 'Montserrat',
  fontWeight: 'bold',
  fontSize: '1rem',
  border: '1px solid rgba(94, 100, 105, 0.5)',
  boxSizing: 'border-box',
  boxShadow: 'none',
  bordeRadius: '6px',
  maxWidth: '480px',
  height: '51px',
    '&:hover': {
      backgroundColor: '#FFFFFF',
      color: 'rgba(0,0,0,.75)',
      border: '1px solid rgba(94, 100, 105, 0.5)',
      boxShadow: 'none',
    }
}));

export const Logo = styled('div')({
  textAlign: 'center',
  margin: '40px 0 30px 0',
});

export const HeaderText = styled(Typography)({
  margin: '20px 0',
  color: '#000000',
  opacity: '50%'
});

export const ContainerForm = styled(Grid)({
  maxWidth: '480px',
  margin: '20px auto',
})
  
export const SecundaryText = styled(Typography)({
  boxSizing: 'border-box',
  padding: '0 0 30px 0',
  textAlign: 'center',
  fontSize: '.9rem',
});

export const Title = styled(Typography)({
  boxSizing: 'border-box',
  margin: '20px 0 60px 0',
  fontSize: '24px',
});

export const ContainerPage = styled(Container)({
  height: '80vh'
});

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export const ModalContainer = styled('div')({
  padding: '20px 40px',
  boxSizing: 'border-box'
});

export const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    color: '#ffffff',
    maxWidth: 220,
    padding: '15px'
  },
}));

export const TitleToltip = styled(Typography)({
  fontFamily: 'Montserrat',
  marginBottom: '5px',
  fontSize: '.9rem',
  fontWeight: 'bold',
  lineHeight: '.9rem'
});

export const TextToltip = styled(Typography)({
  fontFamily: 'Montserrat',
  fontSize: '.7rem',
  lineHeight: '.9rem'
});

export const ButtonEmail = styled(Button)({
  width: '352px',
  textTransform: 'none',
  fontFamily: 'Montserrat',
  fontSize: '1rem',
  fontWeight: 'bold',
  margin: '30px 0',
  color: '#ffffff',
  height: '52px',
  background: theme.palette.primary.main,
  borderRadius: '8px',
  '&:hover': {
    background: theme.palette.primary.main,
  }
});

export const ModalError = styled('div')({
  color: theme.palette.primary.main,
  fontFamily: 'Montserrat',
  fontSize: '.7rem',
  textAlign: 'left',
  marginTop: '5px',
});

export const MessageResponse = styled('p')({
  color: theme.palette.primary.main,
  fontFamily: 'Montserrat',
  fontSize: '1,2rem',
  fontWeight: 'bold'
})