import AppService from './AppService';

class LocalitiesService extends AppService {
  constructor() {
    super();
    this.path = this.getPath('helpers');
  }
  listStates() {
    return this.http.get(`/web${this.path}`);
  }
  
  listCities(stateId) {
    return this.http.get(`/web${this.path}?state_id=${stateId}`);
  }
}

export default LocalitiesService;
