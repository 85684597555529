import PaymentyPage from '../questionnaire-wizard/payment/payment.page';

// eslint-disable-next-line no-undef
// console.log('API_KEY:' + process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const StripePage = () => {
  return <PaymentyPage />;
};

export default StripePage;
