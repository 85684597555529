import React from 'react';
import { Typography, Grid, Container, Box, Link } from '@mui/material';

import { HomeStyles } from './home.styles';

import pictureAdmin from '../../assets/images/imagen_admin.png';

import bmiLogo from '../../assets/images/bmi-logo.svg';
import ascapLogo from '../../assets/images/ascap-logo.svg';
import sesacLogo from '../../assets/images/sesac-logo.svg';
import gmrLogo from '../../assets/images/gmr-logo.png';

import musicCoverage from '../../assets/images/music-coverage.svg';
import fullManagement from '../../assets/images/full-management.svg';
import easyPayments from '../../assets/images/easy-payments.svg';
import usaBased from '../../assets/images/usa-based.svg';

import goldsGymLogo from '../../assets/images/golds-gym-logo.png';
import lifetimeFitnessLogo from '../../assets/images/lifetime-fitness-logo.png'
import tougMudderLogo from '../../assets/images/tough-mudder-logo.png'

import personImg from '../../assets/images/person-oval.png'


const AdminHome = () => {
  const styles = HomeStyles();

  return (
    <>
    <div>
      <Box sx={styles.navbarWrapper}>
        <Container>
          <Container>
            <Box sx={styles.heroInner}>
              <Typography variant='h1' sx={styles.title}>
                Let FitRadio Manage Your <span> Music Licensing </span>
              </Typography>
              <Typography component='p' sx={styles.subTitle}>
                Obtain yearly licensing agreements from ASCAP, BMI, SESAC and GMR for the music played in your gym and fitness classes right from our website. Just fill out the questionnaire, and we&apos;ll calculate the rates for you.
              </Typography>
              <Link sx={[styles.btn, styles.btnWhite]}>Get Started</Link>
            </Box>
            <Box item>
              <Box component='img' src={pictureAdmin} alt='Build-T' sx={[styles.heroImg, styles.adminHeroImg]} />
            </Box>
            <Box sx={styles.logowall}>
              <a href='#' target='_blank'>
                <img src={bmiLogo} alt='' />
              </a>
              <a href='#' target='_blank'>
                <img src={ascapLogo} alt='' />
              </a>
              <a href='#' target='_blank'>
                <img src={sesacLogo} alt='' />
              </a>
              <a href='#' target='_blank'>
                <img src={gmrLogo} alt='' width={90} />
              </a>
            </Box>
            </Container>
        </Container>
      </Box>
      <Box sx={styles.licensingBenefits}>
      <Container>
          <Container>
            <Box sx={styles.titleAdminWrap}>
                <Typography sx={[styles.badgePrimary, styles.bpHomeAdmin]}>BENEFITS</Typography>
                <Typography variant='h2' sx={styles.sectionTitle}>
                  Our licensing benefits
                </Typography>
                <Typography variant='h3' sx={styles.sectionSubTitle}>
                  FITRADIO subscribers recieve an additional 10% OFF discount!
                </Typography>
            </Box>
            <Box sx={styles.licensingList}>
              <Box sx={styles.elementList}>
                <Box component='img' src={musicCoverage} alt='music-coverage' sx={styles.musicIcon} />
                <Typography variant='h4' sx={styles.licensingTitle}>
                  Music Coverage
                </Typography>
                <Typography component='p'>
                  Our Licensing covers the music played in your gym and group
                  fitness classes.
                </Typography>
              </Box>
              <Box sx={styles.elementList}>
                <Box component='img' src={fullManagement} alt='music-coverage' sx={styles.mgmIcon} />
                <Typography variant='h4' sx={styles.licensingTitle}>
                  Full Management
                </Typography>
                <Typography component='p'>
                  We’ll manage all of your licensing agreements, payments, and
                  reporting for you.
                </Typography>
              </Box>
              <Grid sx={styles.elementList}>
                <Box component='img' src={easyPayments} alt='music-coverage' sx={styles.paymentIcon} />
                <Typography variant='h4' sx={styles.licensingTitle}>
                  Easy Payments
                </Typography>
                <Typography component='p'>
                  You’ll only ever have to worry about one monthly payment.
                </Typography>
              </Grid>
              <Box sx={styles.elementList}>
                <Box component='img' src={usaBased} alt='music-coverage' sx={styles.usaBasedIcon} />
                <Typography variant='h4' sx={styles.licensingTitle}>
                  USA Based
                </Typography>
                <Typography component='p'>
                  Available for Gyms Located in the United States.
                </Typography>
              </Box>
            </Box>
            <Box sx={styles.licensingRecommended}>
              <p>For assistance, please contact <a href='mailto:silvia@fitradio.com'>silvia@fitradio.com</a></p>
            </Box>
          </Container>
        </Container>
      </Box>
      <Box sx={styles.upgradeSection}>
      <Container>
          <Container>
            <Box sx={styles.titleAdminWrap}>
                <Typography sx={[styles.badgePrimary, styles.bpHomeAdmin]}>TESTIMONIALS</Typography>
                <Typography variant='h2' sx={styles.sectionTitle}>
                  Our Happy Clients
                </Typography>
            </Box>
            <Box sx={styles.licensingList}>
              <Box sx={styles.testimonalElement}>
                  <Box sx={styles.boxImgTestimonals}>
                  <Box component='img' src={goldsGymLogo} alt='golds-gym-logo' />
                  </Box>
                  <Typography component='p'>
                    &quot;Without Fitradio, I think our gym would not have made as much progress as we have to date. Thank you so much Fitradio team!&quot;
                  </Typography>
                  <Box sx={styles.quoteAuthorBox}>
                    <Box>
                      <Box component='img' src={personImg} alt='person-oval-img'  />
                    </Box>
                    <Box sx={styles.boxTextAuthor}>
                      <Typography variant='h5'>
                      Albert Dufeldorf
                      </Typography>
                      <Typography variant='h6'>
                      Chief Executive at Gold’s Gym
                      </Typography>
                    </Box>
                  </Box>
              </Box>
              <Box sx={styles.testimonalElement}>
                <Box sx={styles.boxImgTestimonals}>
                  <Box component='img' src={lifetimeFitnessLogo} alt='lifetime-fitness-logo'  />
                </Box>
                <Typography component='p'>
                &quot;Without Fitradio, I think our gym would not have made as much progress as we have to date. Thank you so much Fitradio team!&quot;
                </Typography>
                <Box sx={styles.quoteAuthorBox}>
                    <Box>
                      <Box component='img' src={personImg} alt='person-oval-img'  />
                    </Box>
                    <Box sx={styles.boxTextAuthor}>
                      <Typography variant='h5'>
                      Albert Dufeldorf
                      </Typography>
                      <Typography variant='h6'>
                      Chief Executive at Gold’s Gym
                      </Typography>
                    </Box>
                  </Box>
              </Box>
              <Box sx={styles.testimonalElement}>
                <Box sx={styles.boxImgTestimonals}>
                  <Box component='img' src={tougMudderLogo} alt='thoug-mudder-logo'  />
                </Box>
                <Typography component='p' sx={styles.testimonals}>
                &quot;Without Fitradio, I think our gym would not have made as much progress as we have to date. Thank you so much Fitradio team!&quot;
                </Typography>
                <Box sx={styles.quoteAuthorBox}>
                    <Box>
                      <Box component='img' src={personImg} alt='person-oval-img'  />
                    </Box>
                    <Box sx={styles.boxTextAuthor}>
                      <Typography variant='h5'>
                      Albert Dufeldorf
                      </Typography>
                      <Typography variant='h6'>
                      Chief Executive at Gold’s Gym
                      </Typography>
                    </Box>
                  </Box>
              </Box>
            </Box>
            <Box sx={styles.licensingRecommended}>
              <p>For assistance, please contact <a href='mailto:silvia@fitradio.com'>silvia@fitradio.com</a></p>
            </Box>
          </Container>
        </Container>
      </Box>
    </div>
    </>
  );
};

export default AdminHome;
