import { useState } from 'react';

import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';

import { Box, Container, Typography } from '@mui/material';

import AppHelper from '../../core/helpers/AppHelper';
import AuthService from '../../core/services/auth.service';
import InputFieldPassword from '../../core/widgets/input.password.widget';
import {
  BoxError, BoxForm, BoxPswLabels, BtnLogin,
  Title,
} from './reset-password.styles.js';

const ResetPassword = () => {
  const authService = new AuthService();
  let navigate = useNavigate();
  const [textError, setTextError] = useState(null);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const initialValues = {
    password: '',
    passwordConfirmation: '',
  };

  const resetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required('New password is required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        'Must contain 8 characters, one Uppercase, one Lowercase and one Number'
      ),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('password'), ''], 'Passwords must match')
      .required('This field is required'),
  });

  const onSubmit = async (data) => {
    try {
      await authService.resetPassword(data, token);
      navigate('/password-reset', { replace: true });
    } catch (error) {
      setTextError(AppHelper.getError(error));
    }
  };

  return (
    <>
      <div style={{
        flex: 1,
      }}>
        <Container sx={{ textAlign: 'center' }}>
          <Box
            sx={{
              width: { xl: '40%', md: '50%', sm: '80%', xs: '95%' },
              margin: 'auto',

            }}
          >
            <Title>Reset your password</Title>
            <BoxForm>
              <Formik
                initialValues={initialValues}
                validationSchema={resetPasswordSchema}
                onSubmit={(values) => onSubmit(values)}
              >
                {(props) => {
                  return (
                    <Form component='form'>
                      <Box>
                        <Box sx={{ margin: '0 0 10px 0' }}></Box>
                        <BoxPswLabels>
                          <Typography component='label'>New Password</Typography>
                        </BoxPswLabels>
                        <InputFieldPassword
                          name='password'
                          label='Enter new password...'
                          fullWidth
                        />
                      </Box>
                      <Box>
                        <BoxPswLabels>
                          <Typography component='label'>Confirm Password</Typography>
                        </BoxPswLabels>
                        <InputFieldPassword
                          name='passwordConfirmation'
                          label='Confirm your password...'
                          fullWidth
                        />
                      </Box>
                      <Box>
                        <BtnLogin
                          type='submit'
                          variant='contained'
                          disabled={props.isSubmitting}
                          fullWidth
                        >
                          {props.isSubmitting ? 'Loading' : 'Sign In'}
                        </BtnLogin>
                      </Box>
                      <BoxError>{textError}</BoxError>
                    </Form>
                  );
                }}
              </Formik>
            </BoxForm>
          </Box>
        </Container>
      </div>
    </>
  );
};

export default ResetPassword;

ResetPassword.propTypes = {
  isSubmitting: PropTypes.func,
};
