import { styled } from '@mui/material/styles';
import { Typography, Box } from '@mui/material';

export const PageTitle = styled(Typography)({
    padding: '0.5em 0',
    fontFamily: 'Bebas Neue',
    fontSize: '48px',
    fontWeight: 'bold'
});

export const PageSubtitle = styled(Typography)({
    fontFamily: 'Montserrat',
    fontSize: '14px'
});

export const BoxTable = styled(Box)({
    borderRadius: '0.5em', 
    marginTop: '1em', 
    marginBottom: '1em', 
    padding: '1em', 
    background: '#fff', 
    border: '1px solid #e8ecef', 
    boxShadow: '0 16px 24px rgb(0 0 0 / 4%)',
    '& .MuiTableHead-root': {
        '& .MuiTableCell-root': {
            fontFamily: 'Montserrat',
            fontSize: '14px',
            fontWeight: '500',
            color: '#4F5661'
        }
    },
    '& .MuiTableBody-root': {
        '& .MuiTableCell-root': {
            fontFamily: 'Montserrat',
            fontSize: '14px',
            color: '#4F5661',
            fontWeight: '400',
        }
    }
});