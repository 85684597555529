import { Typography, Container, Box, Grid } from '@mui/material';
import { CheckCircle, Download } from '@mui/icons-material';

import {
  BoxTicketMain,
  BoxTicket,
  TitleBox,
  LabelName,
  ContainerAccount,
  LabelTextTitle,
  TitleBoxBootCamp,
  ButtonDownload,
  BoxRowTop,
  BoxRowDown,
  TitleTag,
  PaymentStatus,
  LinksTag,
  Tag,
  ResultTag,
  ResultTagFinal
} from '../assets/styles/account.styles.js';

const AccountPage = () => {
  return (
    <>
      <div>
        <Container>
          <ContainerAccount>
            <BoxTicketMain>
              <TitleBoxBootCamp>Burn Boot Camp</TitleBoxBootCamp>
              <Grid container>
                <Grid container>
                  <Grid item xs={3}>
                    <Box>
                      <LabelName>Name</LabelName>
                      <LabelTextTitle>Susan Smith</LabelTextTitle>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <LabelName>Title</LabelName>
                      <LabelTextTitle>Owner</LabelTextTitle>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <LabelName>Phone</LabelName>
                      <LabelTextTitle>555-555-5555</LabelTextTitle>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <LabelName>Email</LabelName>
                      <LabelTextTitle>sam@gmail.com</LabelTextTitle>
                    </Box>
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    width: '100%',
                    borderBottom: '1px solid #EAE9E9',
                    margin: '1em 0',
                  }}
                />
                <Grid container>
                  <Grid item xs={3}>
                    <Box>
                      <LabelName>Address</LabelName>
                      <LabelTextTitle>
                        123 Rosewell Rd Rosewell, GA 30075
                      </LabelTextTitle>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <LabelName>Music Use</LabelName>
                      <LabelTextTitle>Fitness Classes</LabelTextTitle>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <LabelName>FITRADIO PRO Member?</LabelName>
                      <Typography>
                        <CheckCircle />
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <LabelName>FITRADIO PRO Email?</LabelName>
                      <LabelTextTitle>sam@gmail.com</LabelTextTitle>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </BoxTicketMain>
            <BoxTicket>
              <BoxRowTop>
                <Grid container>
                  <Grid item xs={3}>
                    <Box>
                      <TitleBox>ASCAP</TitleBox>
                    </Box>
                  </Grid>
                  <Grid item xs={9}>
                    <Grid container>
                      <Grid item xs={3}>
                        <Box>
                          <LabelTextTitle>15</LabelTextTitle>
                          <LabelName>
                            # of Group Class Participant Capacity
                          </LabelName>
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box>
                          <LabelTextTitle>5</LabelTextTitle>
                          <LabelName># Classes per Week</LabelName>
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box>
                          <LabelTextTitle>75</LabelTextTitle>
                          <LabelName>
                            Total Group Class Weekly Capacity
                          </LabelName>
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box>
                          <LabelTextTitle>No</LabelTextTitle>
                          <LabelName>Social Events</LabelName>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </BoxRowTop>
              <BoxRowDown>
                <Grid container>
                  <Grid item xs={3}>
                    <LabelTextTitle>$464.00</LabelTextTitle>
                    <LabelName>ASCAP Annual Rate</LabelName>
                  </Grid>
                  <Grid item xs={4}>
                    <LabelTextTitle>$38.67</LabelTextTitle>
                    <LabelName>ASCAP Monthly Rate</LabelName>
                  </Grid>
                  <Grid item xs={2}>
                    <LabelTextTitle>11/01/2021</LabelTextTitle>
                    <LabelName>Start Date</LabelName>
                  </Grid>
                  <Grid item xs={3}>
                    <ButtonDownload>
                      <Download />
                      Download Agreement
                    </ButtonDownload>
                  </Grid>
                </Grid>
              </BoxRowDown>
            </BoxTicket>
            <BoxTicket>
              <BoxRowTop>
                <Grid container>
                  <Grid item xs={3}>
                    <Box>
                      <TitleBox>BMI</TitleBox>
                    </Box>
                  </Grid>
                  <Grid item xs={9}>
                    <LabelTextTitle>150</LabelTextTitle>
                    <LabelName>Total Members</LabelName>
                  </Grid>
                </Grid>
              </BoxRowTop>
              <BoxRowDown>
                <Grid container>
                  <Grid item xs={3}>
                    <LabelTextTitle>$345.00</LabelTextTitle>
                    <LabelName>ASCAP Annual Rate</LabelName>
                  </Grid>
                  <Grid item xs={4}>
                    <LabelTextTitle>$28.75</LabelTextTitle>
                    <LabelName>BMI Monthly Rate</LabelName>
                  </Grid>
                  <Grid item xs={2}>
                    <LabelTextTitle>11/01/2021</LabelTextTitle>
                    <LabelName>Start Date</LabelName>
                  </Grid>
                  <Grid item xs={3}>
                    <ButtonDownload>
                      <Download />
                      Download Agreement
                    </ButtonDownload>
                  </Grid>
                </Grid>
              </BoxRowDown>
            </BoxTicket>
            <BoxTicket>
              <BoxRowTop>
                <Grid container>
                <TitleBox>SESAC</TitleBox>
                </Grid>
              </BoxRowTop>
              <BoxRowDown>
                <Grid container>
                  <Grid item xs={3}>
                    <LabelTextTitle>$347.00</LabelTextTitle>
                    <LabelName>SESAC Annual Rate</LabelName>
                  </Grid>
                  <Grid item xs={4}>
                    <LabelTextTitle>$28.92</LabelTextTitle>
                    <LabelName>SESAC Monthly Rate</LabelName>
                  </Grid>
                  <Grid item xs={2}>
                    <LabelTextTitle>11/01/2021</LabelTextTitle>
                    <LabelName>Start Date</LabelName>
                  </Grid>
                  <Grid item xs={3}>
                    <ButtonDownload>
                      <Download />
                      Download Agreement
                    </ButtonDownload>
                  </Grid>
                </Grid>
              </BoxRowDown>
            </BoxTicket>
            <BoxTicketMain>
              <Grid container>
                <Grid item xs={6}>
                  <Grid container>
                    <Grid item xs={12}>
                      <TitleBox>
                        Totals
                      </TitleBox>
                    </Grid>
                    <Grid item xs={6}>
                      <Box>
                        <TitleTag>Payment Status{' '}</TitleTag>
                        <PaymentStatus>Active</PaymentStatus>
                        <LinksTag>Stripe Account</LinksTag>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <TitleTag>Docusing</TitleTag>
                      <LinksTag>Docusing Link</LinksTag>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={9}>                      
                      <Grid container sx={{borderBottom: '1px solid #EAE9E9', paddingBottom: '0.5em'}}>
                        <Grid item xs={9} sx={{textAlign: 'right', paddingRight: '2.5em'}}>
                          <Tag>ASCAP</Tag>
                          <Tag>BMI</Tag>
                          <Tag>SESAC</Tag>
                          <Tag>Monthly 3% Processing Fee</Tag>
                          <Tag>Monthly Rights Management Fee</Tag>
                        </Grid>
                        <Grid item xs={3} sx={{paddingLeft: '1em'}}>
                        <ResultTag>$38.67</ResultTag>
                          <ResultTag>$28.75</ResultTag>
                          <ResultTag>$28.92</ResultTag>
                          <ResultTag>$2.89</ResultTag>
                          <ResultTag>$6.74</ResultTag>
                        </Grid>
                      </Grid>
                      <Grid container sx={{paddingTop: '0.5em'}}>
                        <Grid item xs={8} sx={{textAlign: 'right', paddingRight: '2em'}}>
                          <Tag>
                          Total Monthly Stripe Payment
                          </Tag>
                        </Grid>
                        <Grid item xs={4} sx={{textAlign: 'right', paddingRight: '2em'}}>
                          <ResultTagFinal>$105.97</ResultTagFinal>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </BoxTicketMain>
          </ContainerAccount>
        </Container>
      </div>
    </>
  );
};

export default AccountPage;
