import { at } from 'lodash';
import { useField } from 'formik';
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect } from 'react';
import PropTypes from 'prop-types';

const Input = styled(TextField)({
  marginBottom: '20px'
})

const InputCodeField = (props) => {
  const { ...rest } = props;
  const [field, meta] = useField(props);
  const { errorMsg, onCleanErrorMsg } = props;

  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return error;
    }
    if (errorMsg !== "" && meta.value !== "") {
      return errorMsg;
    }
  }

  useEffect(() => {
    if (meta.value === "" && errorMsg !== "") {
      onCleanErrorMsg();
    }
  }, [meta.value, errorMsg, onCleanErrorMsg]);
  
  return (
    <Input
      type='text'
      autoComplete='none'
      error={meta.touched && meta.error && true || errorMsg !== "" && meta.value !== ""}
      helperText={_renderHelperText()}
      {...field}
      {...rest}
    />
  );
}

export default InputCodeField;

InputCodeField.propTypes = {
  errorMsg: PropTypes.string,
  onCleanErrorMsg: PropTypes.func,
};
