import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AccountRoute } from './account.route';
import { WebRoute } from './web.route';

const AppRoute = () => (
  <BrowserRouter>
    <Routes>
      <Route path='*' element={<WebRoute />} />
      <Route path='account/*' element={<AccountRoute />} />
    </Routes>
  </BrowserRouter>
);

export default AppRoute;
