import React from 'react';
import { Typography, Container, Box, Link } from '@mui/material';
import {
  BoxFooter,
  FooterContent,
  FooterSocial,
  SocialLinks,
  SocialIco,
  FooterApp,
  FooterList,
  BoxCopyright,
} from './footer.styles';


import logo from '../../../../assets/images/logo.svg';
import facebook from '../../../../assets/images/facebook.svg';
import twitter from '../../../../assets/images/twitter.svg';
import linkedin from '../../../../assets/images/linkedin.svg';
import appStore from '../../../../assets/images/app-store.svg';
import googlePlay from '../../../../assets/images/google-play.svg';

const FooterWidget = () => {
  return (
    <div>
      <BoxFooter>
        <Container>
            <FooterContent>
              <FooterSocial>
                <Box component='img' src={logo} />
                <SocialLinks>
                  <Link href='https://www.facebook.com/FITRADIO'>
                    <SocialIco component='img' src={facebook} />
                  </Link>
                  <Link href='https://twitter.com/fitradio'>
                    <SocialIco component='img' src={twitter} />
                  </Link>
                  <Link href='https://linkedin.com/fitradio'>
                    <SocialIco component='img' src={linkedin} />
                  </Link>
                </SocialLinks>
              </FooterSocial>
              <FooterList>
                <Typography variant='h6'>COMPANY</Typography>
                <Link component='a' href='https://www.fitradio.com/about.html'>
                  About
                </Link>
                <Link
                  component='a'
                  href='https://blog.fitradio.com/contact-us/'
                >
                  Contact
                </Link>
                <Link
                  component='a'
                  href='https://www.fitradio.com/become-a-dj.html'
                >
                  Become a DJ
                </Link>
                <Link component='a' href='https://blog.fitradio.com/'>
                  Blog
                </Link>
              </FooterList>
              <FooterList>
                <Typography variant='h6'>APP</Typography>
                <Link
                  component='a'
                  href='https://blog.fitradio.com/troubleshooting/'
                >
                  Support
                </Link>
                <Link component='a' href='https://gyms.fitradio.com/partners/'>
                  Partners
                </Link>
                <Link component='a' href='https://gyms.fitradio.com/'>
                  Gyms
                </Link>
              </FooterList>
              <FooterList>
                <Typography variant='h6'>TERMS</Typography>
                <Link component='a' href='https://www.fitradio.com/privacy'>
                  Privacy Policy
                </Link>
                <Link component='a' href='https://www.fitradio.com/tos'>
                  Terms of Use
                </Link>
                <Link component='a' href='https://www.fitradio.com/media.html'>
                  Media Kit
                </Link>
              </FooterList>
              <FooterApp>
                <Typography variant='h6'>GET THE APP</Typography>
                <Link
                  component='a'
                  href='https://apps.apple.com/us/app/fit-radio-train-inspired/id440844625'
                >
                  <Box component='img' src={appStore} />
                </Link>
                <Link
                  component='a'
                  href='https://play.google.com/store/apps/details?id=com.fitradio'
                >
                  <Box component='img' src={googlePlay} />
                </Link>
              </FooterApp>
            </FooterContent>
            <BoxCopyright>
              <Typography variant='h6'>
                2021 © FITRADIO, All Rights Reserved. Built with love in the
                USA.
              </Typography>
            </BoxCopyright>
          </Container>
      </BoxFooter>
    </div>
  );
};

export default FooterWidget;
