import { useDispatch, useSelector } from 'react-redux';

import { Box, Container, Typography } from '@mui/material';

import { loginStatus } from '../../app/features/appSlice';
import LoginDialog from './login.dialog';
import { BtnLogin, Title } from './reset-password.styles.js';

const PasswordReset = () => {
  const dispatch = useDispatch();
  const { app } = useSelector((state) => state.app);

  // const [openLogin, setOpenLogin] = useState(false)

  const handleOpenDialog = () => {
    // setOpenLogin(true);
    dispatch(loginStatus());
  };

  return (
    <>
      <div
        style={{
          flex: 1,
        }}
      >
        <Container sx={{ textAlign: 'center' }}>
          <Box
            sx={{
              width: { xl: '40%', md: '50%', sm: '80%', xs: '95%' },
              margin: 'auto',
            }}
          >
            <Title>Password Reset</Title>
            <Box>
              <Typography>You can now use your new password to log in to you account!</Typography>
              <BtnLogin type='submit' variant='contained' fullWidth onClick={handleOpenDialog}>
                Sign In
              </BtnLogin>
              <LoginDialog openDialog={app.loginStatus} />
            </Box>
          </Box>
        </Container>
      </div>
    </>
  );
};

export default PasswordReset;
